import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import ProfileExperience from './ProfileExperience';
import ProfileEducation from './ProfileEducation';
import ProfileProject from './ProfileProject';
import ProfilePayment from './ProfilePayment';
import {getUserDetails,deleteUser, getUserByid ,deleteUserExperienceAdmin, deleteUserEducationAdmin, deleteUserProjectAdmin, deleteUserPaymentAdmin, deleteUserLingueAdmin} from '../../actions/userActions'
import formatDate from './../../utils/formatDate';
import Evento from '../dashboard/Evento';
import Sidebar from '../dashboard/Sidebar';
import SidebarAdmin from '../Admin/SidebarAdmin';
import Header from '../layout/Header';
import EventoAdmin from '../Admin/sidebarAdmin/EventoAdmin';
import TabEvent from './../Admin/sidebarAdmin/TabEvent'
import Print from '../curriculum/admincur/Print';
import { USER_BYID_RESET, USER_BYID_SUCCESS, USER_LIST_RESET } from '../../constants/userConstants';
import ComponentToPrint from '../curriculum/admincur/ComponentToPrint';


const Profile = ({match,  history, location }) => {
  
  
  const dispatch = useDispatch()
  const userId = match.params.id
  //const userLogin = useSelector((state) => state.userLogin)
  //const { userInfo } = userLogin

  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid
  console.log(user)
 
  const downCur = (name) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
fetch(`https://api.gestionale.ipsedocet.com/bucketCur?key=curriculum/` +name, config)
.then((response)=>{

  console.log(response.body)
 
    response.json().then(res=>{

      console.log(res.result.data)
      var myBlob = new Blob ([new Uint8Array(res.result.data)]);
      let url = window.URL.createObjectURL(myBlob);
      let a = document.createElement('a');
    

      a.href = url;
      a.download = name;
      a.click()

    }

      )
  
  
})
}
  
  
  const deleteHandler = (_id) => {
  
    if (window.confirm('Sei sicuro?')) {
      dispatch(deleteUser(_id))
      
      history.push('/profiles')
    }
  }


  useEffect(() => {
    if (!user ?? user.isAdmin ) {
      history.push('/login')
    } 
    else {
      
      if (!user || !user.name || !user.username) {
        
       
        dispatch(getUserByid(userId))
        
        dispatch(getUserByid(userId))
       

        
      } 
    }
  }, [dispatch,user, history,userId,  /*userInfo*/ ])


  const pippo = () => {
    
    window.location=('/profiles')
    dispatch({
      type: USER_BYID_RESET,
    })
  }

  const ListaCvReset = () => {
    
    window.location=('/list-curriculum')
    dispatch({
      type: USER_BYID_RESET,
    })
  }
  
  
  const deleteExperience= (userId,_id) => {

    if (window.confirm('Sei sicuro?')) {
      dispatch(getUserByid(userId))
        dispatch(deleteUserExperienceAdmin(userId,_id,))
        dispatch(getUserByid(userId))
       //dispatch(getUserDetails(userInfo._id))
        //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
        //dispatch({type: USER_UPDATE_PROFILE_RESET })
   
    }
    }

    const deleteEducation= (userId,_id) => {

      if (window.confirm('Sei sicuro?')) {
        dispatch(getUserByid(userId))
          dispatch(deleteUserEducationAdmin(userId,_id,))
          dispatch(getUserByid(userId))
         //dispatch(getUserDetails(userInfo._id))
          //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
          //dispatch({type: USER_UPDATE_PROFILE_RESET })
     
      }
      }

      const deleteProject= (userId,_id) => {

        if (window.confirm('Sei sicuro?')) {
          dispatch(getUserByid(userId))
            dispatch(deleteUserProjectAdmin(userId,_id,))
            dispatch(getUserByid(userId))
           //dispatch(getUserDetails(userInfo._id))
            //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
            //dispatch({type: USER_UPDATE_PROFILE_RESET })
       
        }
        }

        const deletePayment= (userId,_id) => {

          if (window.confirm('Sei sicuro?')) {
            dispatch(getUserByid(userId))
              dispatch(deleteUserPaymentAdmin(userId,_id,))
              dispatch(getUserByid(userId))
             //dispatch(getUserDetails(userInfo._id))
              //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
              //dispatch({type: USER_UPDATE_PROFILE_RESET })
         
          }
          }
          const deleteLingue= (userId,_id) => {

            if (window.confirm('Sei sicuro?')) {
              dispatch(getUserByid(userId))
                dispatch(deleteUserLingueAdmin(userId,_id,))
                dispatch(getUserByid(userId))
               //dispatch(getUserDetails(userInfo._id))
                //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
                //dispatch({type: USER_UPDATE_PROFILE_RESET })
           
            }
            }


  console.log(user)
  var lingues=[];
  
  if(user.lingue === undefined){
 
  }else{
    
  lingues = user.lingue.map((ling) => (
    
          <tr key={ling._id}>
            <td className="tableLingText">{ling.lingua}</td>
      <td className="tableLingText">{ling.livascolt}</td>
      <td className="tableLingText">{ling.livlett}</td>
      <td className="tableLingText">{ling.livinter}</td>
      <td className="tableLingText" >{ling.livprod}</td>
      <td className="tableLingText">{ling.livprodscrt}</td>
     
      <td className="noone">
        <button
          onClick={() => deleteLingue(userId, ling._id)}
          className="btn btn-danger"
        >
        <i class="fas fa-trash-alt"></i>
        </button>
      </td>
    </tr>
 

  ));

  }


  var experiences=[];
  if(user.experience === undefined){
  
  }else{
    
  experiences = user.experience.map((exp) => (
    <div>
    <h2 className="text-dark">{exp.company} <button
          onClick={() => deleteExperience(userId, exp._id)}
          className="btn btn-danger"
        >
          <i class="fas fa-trash-alt"></i>
          </button></h2>
    <p>
      {formatDate(exp.from)} - {exp.to ? formatDate(exp.to) : 'Now'}
    </p>
    <p>
      <strong>Posizione: </strong> {exp.title}
    </p>
    <p>
      <strong>Luogo: </strong> {exp.luogo}
    </p>
    <p>
      <strong>Descrizione: </strong> {exp.description}
    </p>
    
  </div>

  ));

  }
  var educations=[];
if(user.education === undefined){
  
}else{

   educations= user.education.map((edu) => (
    <div>
    <h2 className="text-dark">{edu.school}  <button
          onClick={() => deleteEducation(userId, edu._id)}
          className="btn btn-danger"
        >
          <i class="fas fa-trash-alt"></i>
          </button></h2>
    <p>
      {formatDate(edu.from)} - {edu.to ? formatDate(edu.to) : 'Now'}
    </p>
    <p>
      <strong>Grado: </strong> {edu.degree}
    </p>
    <p>
      <strong>Campo di studi : </strong> {edu.fieldofstudy}
    </p>
    <p>
      <strong>Descrizione: </strong> {edu.description}
    </p>
  </div>

  ));
}

var payments=[];
if(user.payment===undefined){
  
}else{

  payments= user.payment.map((pay) => (
    <div>
    <h2 className="text-dark">{pay.title}   <button
          onClick={() => deletePayment(userId, pay._id)}
          className="btn btn-danger"
        >
          <i class="fas fa-trash-alt"></i>
          </button></h2>
    <p>
    <strong>Data: </strong> {formatDate(pay.from)} - {formatDate(pay.to) }
    </p>
    <p>
      <strong>Saldo: </strong> {pay.saldo}
    </p>
    
    <p>
      <strong>Cedolino: </strong> {pay.filecaricato }
    </p>
  </div>

  ));
  }

//   var filepayments=[];
// if(user.payment===undefined){
  
// }else{

//   filepayments= user.payment.file.map((file) => (
//     <div>
//     <h2 className="text-dark">{file.name}   <button
//           onClick={() => deletePayment(userId, file._id)}
//           className="btn btn-danger"
//         >
//           <i class="fas fa-trash-alt"></i>
//           </button></h2>
   
//   </div>

//   ));
//   }


  var competenze=[];
if(user.skills===undefined){
  
}else{

  competenze= user.skills.map((skil) => (
    <div>
    <h2 className="text-dark"><i className='fas fa-check' />{' '}{  skil}</h2>
    
  </div>

  ));
  }
  

var projects=[];
if(user.project===undefined){
  
}else{

  projects = user.project.map((proj) => (
    <div>
    <h2 className="text-dark">{proj.title}   <button
          onClick={() => deleteProject(userId, proj._id)}
          className="btn btn-danger"
        >
          <i class="fas fa-trash-alt"></i>
          </button></h2>
    <p>
    {formatDate(proj.from)} - {proj.to ? formatDate(proj.to) : 'Now'}    </p>
    <p>
      <strong>Luogo: </strong> {proj.luogo}
    </p>
    <p>
      <strong>Descrizione: </strong> {proj.description}
    </p>
    
  </div>
  


  ));
  }
  return (
    <Fragment>
    <Header />

    {user.name !== undefined ? (
      <Fragment>
    <div className="profile-top bg-primary p-2">
      
      <h1 className="large">{user.name}{' '}{user.surname}</h1>
      <p className="lead">
        {user.status} {user.company && <span> presso {user.company}</span>}
      </p>
      <h2>{user.situazione} </h2>
      <h5> {user.datbir}</h5>
      <p>{user.location && <span>{user.location}</span>}</p>
      
      <div className="icons my-1">
      </div>
    </div>

   
    <div>
    <div >
      
    <div className="sidebar">
       
        
        <ul >
        <div className="divsid" >
            
        <li >
        <Link className="sidebarlist"  to={`/profile/${userId}`} >
        <i className='fas fa-user-circle ' /> <t className="hide-sm">Profilo</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   onClick={()=>pippo()} >
        <i class="fas fa-users"></i> <t className="hide-sm">Dipendenti </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   onClick={()=>ListaCvReset()} >
        <i class="fas fa-users"></i> <t className="hide-sm">Lista CV </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   to={`/edit-profile/${userId}`} >
        <i className='fas fa-cogs' /> <t className="hide-sm">Edit </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to={`/add-experience/${userId}`}>
          <i className='fab fa-black-tie ' /> <t className="hide-sm">Lavoro </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to={`/add-education/${userId}`} >
          <i className='fas fa-graduation-cap ' /> <t className="hide-sm">Studi </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/add-project/${userId}`}>
          <i className='fas fa-briefcase ' />  <t className="hide-sm">Progetto </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to= {`/add-payment/${userId}`}>
          <i className='fas fa-credit-card ' /> <t className="hide-sm">Pagamento </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to= {`/add-lingue/${userId}`}>
        <i class="fas fa-language"/> <t className="hide-sm">Lingue </t>
        </Link>
        </li>
        </div>
         <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/curriculum/${userId}`}>
        <i class="far fa-file"/>  <t className="hide-sm">Curriculum </t>
        </Link>
        </li>
        </div> 

     
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-documenti/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Documenti</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-pagamenti/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Estract Pay</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-event/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Estract Eve </t>
        </Link>
        </li>
        </div>
        <div className="divsid">
          <div className="Bottoneeliminauser">
            <button className="btn btn-danger" onClick={() => deleteHandler(user._id)}>
              <i className="fas fa-user-minus" /> <t className="hide-sm">Delete Account</t>
            </button>
            </div>
          </div>
       
       
        </ul>
        </div>
        </div>
        {/*<Evento evento={user.evento}/>*/}
  <EventoAdmin  dipendente  = {userId} evento={user.evento}/>
</div>

<div>

</div>
<div className='contdash'>
    {user.bio && (
      <Fragment>
        <h2 className='text-primary'>{user.name.trim().split(' ')[0]} Descrizione</h2>
        <p>{user.bio}</p>
        <div className='line' />
      </Fragment>
    )}
    <h2 className='text-primary'>Competenze</h2>
    <div className='skills'> 
           {competenze}
    </div>
    <div hidden={user.curriculum === undefined} >
    <b>Scarica Curriculum {' '}</b>
    <button  className="btn btn-success"
          onClick={() => downCur(user.curriculum)}
          
        ><i class="fas fa-download"></i></button>
    </div>
  </div>

  <div className="contdash">
        <h2 className="text-primary">ESPERIENZE LAVORATIVE</h2 >
        {experiences}
      </div>
      <div className="contdash">
      <h2  className="text-primary">ESPERIENZE FORMATIVE</h2 >
        {educations}
      </div>
      <div className="contdash">
      <h2  className="text-primary">PAGAMENTI</h2 >
        {payments}
      </div>
      <div className="contdash">
      <h2  className="text-primary">PROGETTI</h2 >
        {projects}
      </div>
      <div className="contdash">
      <h2 className="text-primary">LINGUE</h2>
      
      <table className="tableLing">
      
        <thead>
          <tr>
            <th>Lingua</th>
            <th>Ascolto</th>
            <th >Lettura</th>
            <th >Interazione</th>
            <th >Produzione</th>
            <th >Prod. Scritta</th>
            
          </tr>
        </thead>
        <tbody>{lingues}</tbody>
        
      </table>
      
      </div>
      
       {/* <div className="contdash">
      <h2  className="text-primary">PCurri</h2 >
     <Print dipendente={userId}/>
        
      </div>  */}

       
        
         
            
     
      </Fragment>
    
    ) : (
    <Fragment>
      <Spinner />
      
    </Fragment>
    )}
     </Fragment>
    );
  };




  

export default Profile;
