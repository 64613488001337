import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Navbar from './components/layout/Header';
import LandingScreen from './screen/LandingScreen';



// Redux




// import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import ProfileForm from './components/profile-forms/ProfileForm';
import AddExperience from './components/profile-forms/AddExperience';
import AddEducation from './components/profile-forms/AddEducation';
import Profiles from './components/profiles/Profiles';
import Profile from './components/profile/Profile';


import NotFound from './components/layout/NotFound';
import AddProject from './components/profile-forms/AddProject';
/*import AddPayment from './components/profile-forms/AddPayment';*/
import AddEvento from './components/profile-forms/AddEvento';


import AddExperienceAdmin from './components/Admin/sidebarAdmin/AddExperienceAdmin';
import AddEducationAdmin from './components/Admin/sidebarAdmin/AddEducationAdmin';
import AddPaymentAdmin from './components/Admin/sidebarAdmin/AddPaymentAdmin';
import AddProjectAdmin from './components/Admin/sidebarAdmin/AddProjectAdmin';



import RegisterScreen from './screen/RegisterScreen';
import LoginScreen from './screen/LoginScreen';
import './App.css';
// import Header from './components/layout/Header';
import ProfileEdit from './components/profile-forms/ProfileEdit';
import ListEventi from './components/profile/ListaEventi';
 import Print from './components/curriculum/admincur/Print';
import Print2 from './components/curriculum/usercur/Print2';
import CurriculumEdit from './components/profile-forms/CurriculumEdit';
import AddLingueAdmin from './components/Admin/sidebarAdmin/AddLingueAdmin';
import AddLingue from './components/profile-forms/AddLingue';
import EditSituazione from './components/Admin/sidebarAdmin/EditSituazione';
import ListPagamenti from './components/profile/ListaPagamenti';
import ListCurriculum from './components/profile/ListCurriculum';
import ListEventiUser from './components/profile/ListaEventiUser';
import ListDocumenti from './components/profile/ListaDocumenti';
import AddDocumentiAdmin2 from './components/Admin/sidebarAdmin/AddDocumentAdmin2';


const App = () => {
 

  return (
    
      <Router>
        
         
          <main>
          <Switch>
            <Route exact path="/" component={LandingScreen} />
            
            <Route exact path="/register" component={RegisterScreen} />
            <Route exact path="/login" component={LoginScreen} />
            <Route exact path="/profiles" component={Profiles} />
            <Route exact path="/profile/:id" component={Profile} />
            <Route exact path="/list-event/:id" component={ListEventi} />
            <Route exact path="/list-eventi/" component={ListEventiUser} />
            <Route exact path="/list-pagamenti/:id" component={ListPagamenti} />
            <Route exact path="/list-curriculum/" component={ListCurriculum} />

            <Route exact path="/list-documenti/:id" component={ListDocumenti} />

            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/create-profile" component={ProfileForm} />
            <Route exact path="/edit-profile" component={ProfileEdit} />

            <Route exact path="/edit-profile/:id" component={EditSituazione} />


            <Route exact path="/edit-curriculum" component={CurriculumEdit} />

            <Route exact path="/add-lingue" component={AddLingue} />
            <Route exact path="/add-experience" component={AddExperience} />
            <Route exact path="/add-education" component={AddEducation} />
            <Route exact path="/add-project" component={AddProject} />
           
            <Route exact path="/add-evento" component={AddEvento} />

            <Route exact path="/add-lingue/:id" component={AddLingueAdmin} />
            <Route exact path="/add-experience/:id" component={AddExperienceAdmin} />
            <Route exact path="/add-education/:id" component={AddEducationAdmin} />
            <Route exact path="/add-project/:id" component={AddProjectAdmin} />
            <Route exact path="/add-payment/:id" component={AddPaymentAdmin} />

            <Route exact path="/curriculum/:id" component={Print} /> 
            <Route exact path="/curriculum/" component={Print2} /> 

      
            <Route component={NotFound} />
            </Switch>
            </main>
         
      </Router>
    
  );
};

export default App;
