
import React, { useRef, Component, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from "react-router-dom"
import { render } from "react-dom";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import './CurriculumTemplate.css';
import formatDate from "../../../utils/formatDate";


class ComponentToPrint extends Component {
    constructor(props){
        super(props)
        this.state={
            info:{},
          id : this.props.test  
        }
        this.state.info.experience = []
        this.state.info.education = []
        this.state.info.skills = []
        this.state.info.lingue = []
        this.state.info.hobby = []
        this.state.info.competenzedig = []
        
    }
    componentDidMount() {
        //const userId= match.params.id
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
            },
        }
        axios.get(`https://api.gestionale.ipsedocet.com/api/users/anagrafica/`+ this.state.id, config)
            .then(response => {
                this.setState({info : response.data})
               
            })
            .catch(error => {
                this.setState({ errorMessage: error.message });
                alert(error)
                console.error('There was an error!', error);
            });
    }
    render() {
     
        return (
            <div>

                
                <div className=''>
                    <div className=''>
                       
                        <p className="titleparag">INFORMAZIONI PERSONALI</p>
                    </div>
                    <div className="infoanagrafica">
                    <p className="anagraficap">{this.state.info.name}{' '}{this.state.info.surname}</p> 
                    <p className="anagraficap"><i class="fas fa-map-marked-alt"></i> {this.state.info.indirizzo}</p>
                    <p className="anagraficap"><i class="fas fa-mobile-alt"></i> {this.state.info.cellulare}</p>
                    <p className="anagraficap"><i class="fas fa-envelope"></i> {this.state.info.email2}</p>

                        
                        
                        <p className='dati-ana'>Sesso:</p> <p className='anagraficap'>{this.state.info.genere}</p> 
                        <p className='dati-ana'>Data di nascita:</p> <p className='anagraficap'>{this.state.info.datbir}</p>
                        <p className='dati-ana'>Nazionalità</p> <p className='anagraficap'>{this.state.info.nazionalit}</p>
                    </div>
                </div>
                <div className =''>
              <div className = ''>
                  <div className = ''>
                      
                      
                  </div>
                   <div>
                      <Fragment>
                          <div>
                          <p className="titleparag">ESPERIENZA PROFESSIONALE</p>
                          </div>
                          <div className="tableExpdiv">
                      <table className="tableExp">
                          <thead>
                              <tr>
                                
                                <th>{''}</th>
                                <th>{' '}</th>
                              </tr>
                          </thead>
                       
                          {   
                          
                            this.state.info.experience.map((exp) => (
                            
                          <tbody>
                              <tr>
                                <td className="tableData-Data"><p className="paragTableExp">{formatDate(exp.from)} - {exp.to ? formatDate(exp.to) : 'Oggi'}</p> </td>
                                <td className="tableData-Descr"><p className='dati-ana'>{exp.title}</p><p className='dati-ana'>{exp.company} - {exp.luogo}</p><p className="paragTableExpDesc">{exp.description}</p></td>
                              </tr>
                          </tbody>
                      
                            
                      ))}
                      </table>
                      </div>
                      </Fragment>
                      <Fragment>
                          <div>
                          <p className="titleparag">ISTRUZIONE E FORMAZIONE</p>
                          </div>
                          <div className="tableEdudiv">
                      <table>
                          <thead>
                              <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr>
                          </thead>
                       
                          {   
                            this.state.info.education.map((edu) => (
                            
                          <tbody>
                              <tr>
                                <td className="tableData-Data"><p className="paragTableExp">{formatDate(edu.from)} - {edu.to ? formatDate(edu.to) : 'Oggi'}</p></td>
                                <td className="tableData-Descr"><p className='dati-ana'>{edu.fieldofstudy} - {edu.degree}</p><p className='dati-ana'>{edu.shool} - {edu.luogo}</p><p className="paragTableExpDesc">{edu.description}</p></td>

                              </tr>
                          </tbody>
                      
                            
                      ))}
                      </table>
                      </div>
                      </Fragment>
                      <Fragment>
                          <p className="titleparag">COMPETENZE PERSONALI</p>

                          <Fragment>
                          <div>
                                <p className="titleparag">Lingue Straniere</p>
                        </div>
                              <div className="tablelinguadiv">
                              <table className="tablelingua">
                                  <thead>
                                      <tr>
                                          
                                          <th className="th-tablelinguadiv1">COMPRENSIONE</th>
                                          <th className="th-tablelinguadiv2">PARLATO</th>
                                          <th className="th-tablelinguadiv3">PRODUZIONE SCRITTA</th>
                                      </tr>
                                  </thead>
                              </table>
                              </div>
                              <div className="tablelinguadiv2">
                      <table className="tablelingua">
                          <thead>
                              <tr>
                                <th className="th-tablelingua">{' '}</th>
                                <th className="th-tablelingua1">Ascolto </th>
                                <th className="th-tablelingua2">Lettura</th>
                                <th className="th-tablelingua3">Interazione</th>
                                <th className="th-tablelingua4">Produzione</th>
                                <th className="th-tablelingua5">Produzione Scritta</th>
                              </tr>
                          </thead>
                       
                          {   
                            this.state.info.lingue.map((ling) => (
                            
                          <tbody>
                              <tr>
                                <td><p className='dati-ana'>{ling.lingua} </p></td>
                                <td className="th-tabledina1"><p className='dati-ana'>{ling.livascolt}</p></td>
                                <td className="th-tabledina2"><p className='dati-ana'>{ling.livlett}</p></td>
                                <td className="th-tabledina3"><p className='dati-ana'>{ling.livinter} </p></td> 
                                <td className="th-tabledina4"><p className='dati-ana'>{ling.livprod}</p></td>
                                <td className="th-tabledina5"><p className='dati-ana'>{ling.livprodscrt}</p></td>
                              </tr>
                          </tbody>
                          
                      
                            
                      ))}
                      </table>
                      </div>
                      <div>
                          <p className="legend-ling">Livelli: A1 e A2: Utente base - B1 e B2: Utente autonomo - C1 e C2: Utente avanzato</p>
                      </div>
                      </Fragment>
                      </Fragment>
                      <Fragment>
                      <div>
                      <p className="titleparag">Competenze professionali </p>
                             </div>
                       
                          {   
                          
                            this.state.info.skills.map((skil) => (
                            
                          <div>
                             
                            <edo>
                                <li className="listaComp">{skil}</li>
                                
                            </edo>
                              
                          </div>
                      
                            
                      ))}
                      
                      </Fragment>
                      <div>
                      <Fragment>
                      <div>
                      <p className="titleparag">Competenze Digitali </p>
                             </div>
                       
                          {   
                          
                            this.state.info.competenzedig.map((comp) => (
                            
                          <div>
                             
                            <edo>
                                <li className="listaComp">{comp}</li>
                                
                            </edo>
                              
                          </div>
                      
                            
                      ))}
                      
                      </Fragment>
                      <Fragment>
                          <div>
                          <p className="titleparag">Ulteriori Informazione e Hobby</p>
                          </div>
                          {   
                          
                          this.state.info.hobby.map((hob) => (
                          
                        <div>
                           
                          <edo>
                              <li className="listaComp">{hob}</li>
                              
                          </edo>
                            
                        </div>
                    
                          
                    ))}
                      </Fragment>
                      </div>
                     <div>
                     <p className="titleparag"> Trattamento dei dati personali</p>
                         <p className="trattDati">Autorizzo il trattamento dei miei dati </p>
                         <p className="trattDati"> personali ai sensi del Decreto Legislativo 30 giugno 2003, n. 196 "Codice in materia di protezione dei dati personali.</p>
                     </div>
                  </div> 
                  <div>

                      
                  </div>
                  
              </div>
              </div>
            </div>
        );
    }
}

export default ComponentToPrint;

