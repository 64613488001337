import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';


import {
  userLoginReducer,
  userRegisterReducer,

  userAddLingueReducer,
  userDeleteLingueReducer,
  userAddExperienceReducer,
  userDeleteExperienceReducer,
  userAddEducationReducer,
  userDeleteEducationReducer,
  userAddEventoReducer,
  userDeleteEventoReducer,
  userAddPaymentReducer,
  userDeletePaymentReducer,
  userAddProjectReducer,
  userDeleteProjectReducer,
  userGetEventoReducer,

  userDetailsReducer,
  userByidReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userUpdateBloccoReducer,

  userAddLingueAdminReducer,
  userAddExperienceAdminReducer,
  userAddEducationAdminReducer,
  userAddPaymentAdminReducer,
  userAddProjectAdminReducer,
  userAddEventoAdminReducer,
  userEditEventoAdminReducer,
  userEditEventoReducer,
  userGetEventoAdminReducer,

  userAddDocumentiAdminReducer,

  userDeleteLingueAdminReducer,
  userDeleteExperienceAdminReducer,
  userDeleteEducationAdminReducer,
  userDeleteProjectAdminReducer,
  userDeletePaymentAdminReducer,
  userDeleteEventoAdminReducer,
  userDeleteDocumentiAdminReducer,

} from './reducers/userReducers'





// set up a store subscription listener
// to store the users token in localStorage

// initialize current state from redux store for subscription comparison
// preventing undefined error


const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userByid: userByidReducer,
  userUpdateProfile: userUpdateProfileReducer,

  userAddLingue :userAddLingueReducer,
  userDeleteLingue :userDeleteLingueReducer,
  userAddExperience :userAddExperienceReducer,
  userDeleteExperience :userDeleteExperienceReducer,
  userAddEducation :userAddEducationReducer,
  userDeleteEducation :userDeleteEducationReducer,
  userAddEvento :userAddEventoReducer,
  userDeleteEvento :userDeleteEventoReducer,
  userAddPayment :userAddPaymentReducer,
  userDeletePayment :userDeletePaymentReducer,
  userAddProject :userAddProjectReducer,
  userDeleteProject :userDeleteProjectReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userUpdateBlocco: userUpdateBloccoReducer,

  userAddLingueAdmin:userAddLingueAdminReducer,
  userAddExperienceAdmin:userAddExperienceAdminReducer,
  userAddEducationAdmin:userAddEducationAdminReducer,
  userAddProjectAdmin:userAddProjectAdminReducer,
  userAddPaymentAdmin:userAddPaymentAdminReducer,
  userAddEventoAdmin:userAddEventoAdminReducer,
  userEditEventoAdmin:userEditEventoAdminReducer,
  userEditEvento:userEditEventoReducer,
  userGetEventoAdmin:userGetEventoAdminReducer,
  userGetEvento:userGetEventoReducer,

  userAddDocumentiAdmin:userAddDocumentiAdminReducer,

  userDeleteLingueAdmin :userDeleteLingueAdminReducer,
  userDeleteExperienceAdmin :userDeleteExperienceAdminReducer,
  userDeleteEducationAdmin :userDeleteEducationAdminReducer,
  userDeleteProjectAdmin :userDeleteProjectAdminReducer,
  userDeletePaymentAdmin :userDeletePaymentAdminReducer,
  userDeleteEventoAdmin :userDeleteEventoAdminReducer,

  userDeleteDocumentiAdmin :userDeleteDocumentiAdminReducer,
  
 
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null


const initialState = {
  evento: {},
  userLogin: { userInfo: userInfoFromStorage }
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)
export default store;
