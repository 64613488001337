import React, { Fragment, useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'
import {  addUserExperienceAdmin, getUserByid } from '../../../actions/userActions';
import Header from '../../layout/Header';

const AddExperienceAdmin = ({ location, match, history }) => {
  const userId = match.params.id
  const [company, setCompany] = useState('')
  const [title, setTitle] = useState('')
  const [luogo, setLuogo] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [current, setCurrent] = useState(false)
  const [description, setDescription] = useState('')

  const dispatch = useDispatch()
  

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  
  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid

  const userAddExperience = useSelector((state) => state.userAddExperience)
  const { success } = userAddExperience

  const redirect = location.search ? location.search.split('=')[1] : `/profile/${userId}`


 
  useEffect(() => {
    if (!user) {
      history.push('/login')
    } else {
      if (!user || !user.username || success) {
        
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserByid(userId))
        
   
      } 
    }
  }, [dispatch, history, userInfo, user, success])


const submitHandler = (e) => {
  e.preventDefault()
  dispatch(getUserByid(userId))
    dispatch(addUserExperienceAdmin(userId, {  company, title, luogo, from, to, current, description}))
    //dispatch({ type: USER_UPDATE_SUCCESS })
    dispatch(getUserByid(userId))
      history.push(redirect)
    
}

  return (
    <Fragment>
      <Header />
      <h1 className="large text-primary">Agg. Esperienza a {user.name} {user.surname}</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Aggiungi ogni posizione come programmator/developer ricoperta in passato
        
      </p>
      <small>* = campo richiesto</small>
      <form
        className="form"
        onSubmit={submitHandler}
      >
        <div className="form-group">
          <input
            type="text"
            placeholder="* Posizione Lavorativa"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="* Azienda"
            name="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Luogo"
            name="luogo"
            value={luogo}
            onChange={(e) => setLuogo(e.target.value)}
          />
        </div>
        <div className="form-group">
          <h4>Data Inizio</h4>
          <input type="date" name="from" value={from} onChange={(e) => setFrom(e.target.value)} />
        </div>
        <div className="form-group">
          <p>
            <input
              type="checkbox"
              name="current"
              onChange={(e) => setCurrent(!current)}
            />{' '}
            Lavoro Attuale
          </p>
        </div>
        <div className="form-group">
          <h4>Data Fine</h4>
          <input
          placeholder=""
            type="date"
            name="to"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            disabled={current}
          />
        </div>
        <div className="form-group">
          <textarea
            name="description"
            cols="30"
            rows="5"
            placeholder="Descrizione Mansioni"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to={`/profile/${userId}`}>
          Indietro
        </Link>
      </form>
    </Fragment>
  );
};



export default AddExperienceAdmin;
