import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import formatDate from '../../utils/formatDate';
import {login,  deleteUserExperience,deleteUserEducation,deleteUserPayment,deleteUserProject,deleteUserLingue  } from '../../actions/userActions'
import {USER_UPDATE_PROFILE_SUCCESS,USER_UPDATE_PROFILE_RESET ,USER_DETAILS_REQUEST,USER_DETAILS_SUCCESS,USER_LOGIN_SUCCESS, USER_UPDATE_PROFILE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_REQUEST, USER_DETAILS_RESET} from '../../constants/userConstants'
import Experience from './Experience';
import Education from './Education';
import { getUserDetails,deleteUser } from '../../actions/userActions'
import Project from './Project';
import Payment from './Payment';
import Evento from './Evento';
import Header from '../layout/Header';
import Sidebar from './Sidebar';
import { preventDefault } from '@fullcalendar/common';

const Dashboard = ({ location, history }) => {
  
  const dispatch = useDispatch()


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails


  const redirect = location.search ? location.search.split('=')[1] : '/dashboard'
  const deleteHandler = (_id) => {
  
    if (window.confirm('Sei sicuro?')) {
      dispatch(deleteUser(_id))
      
      window.location.reload('/register')
    }
  }


  const downCur = (name) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
fetch(`https://api.gestionale.ipsedocet.com/bucketCur?key=curriculum/`+name, config)
.then((response)=>{

  console.log(response.body)
 
    response.json().then(res=>{

      console.log(res.result.data)
      var myBlob = new Blob ([new Uint8Array(res.result.data)]);
      let url = window.URL.createObjectURL(myBlob);
      let a = document.createElement('a');
    

      a.href = url;
      a.download = name;
      a.click()

    }

      )
  
  
})
}



  const downPay = (name) => {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
        },
      }
  fetch(`https://api.gestionale.ipsedocet.com/bucket/?key=pagamenti/`+name, config)
  .then((response)=>{

    console.log(response.body)
   
      response.json().then(res=>{

        console.log(res.result.data)
        var myBlob = new Blob ([new Uint8Array(res.result.data)]);
        let url = window.URL.createObjectURL(myBlob);
        let a = document.createElement('a');
      console.log("cia")

        a.href = url;
        a.download = name;
        a.click()

      }

        )
    
    
  })
  }

  // const downPay = (name) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
  //     },
  //   }
  //   fetch("https://api.gestionale.ipsedocet.com/bucket?key="+ name, config)
  //   .then((res)=>{
  //     console.log(res)
  //     res.blob(name).then(blob => {
  //       let url = window.URL.createObjectURL(blob);
  //       let a = document.createElement('a');
  //       a.href = url;
  //       a.download = name;
  //       a.click()
  //     })
  //   })
  // }
    // fetch("https://api.gestionale.ipsedocet.com/bucket?key"+ name, config)

{/*
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const userDeleteExperience= useSelector((state) => state.userDeleteExperience)
  const { successDeleteExperience } = userDeleteExperience

  const userDeleteEducation = useSelector((state) => state.userDeleteEducation)
  const { successDeleteEducation } = userDeleteEducation

  const userDeleteProject = useSelector((state) => state.userDeleteProject)
  const { successDeleteProject } = userDeleteProject

  const userDeletePayment = useSelector((state) => state.userDeletePayment)
  const { successDeletePayment } = userDeletePayment*/}

  


  console.log(user)

  useEffect(() => {
    
    if (!userInfo) {
      history.push('/login')
     
    } else {
      if (!user || !user.username) {
        //  dispatch({type: USER_DETAILS_REQUEST})
        // alert('1')
        // dispatch(getUserDetails(userInfo._id)) 
        // alert("2") 
        
        // alert("PIPPO")
        dispatch(getUserDetails(userInfo._id))  
        
        
      } 
    }
  }, [dispatch,  userInfo,user,/*history, successDeleteExperience,successDeleteEducation,successDeleteProject,successDeletePayment, success */ ])

  // const deleteHandler =(_id) => {
  //   if (window.confirm('Sei sicuro ###?')) {
  //     dispatch(getUserDetails(userInfo._id))
  //       dispatch(deleteUser(_id))
  //       dispatch(getUserDetails(userInfo._id))
  //      //dispatch(getUserDetails(userInfo._id))
  //       //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
  //       //dispatch({type: USER_UPDATE_PROFILE_RESET })
   
  //   }
  //   }
    
  const deleteLingue= (_id) => {

    if (window.confirm('Sei sicuro?')) {
   
      
      dispatch(deleteUserLingue(_id))
      
     
   
    }
    }

    var lingues=[];
    if(user.lingue === undefined){
    
    }else{
      
    lingues = user.lingue.map((ling) => (
      
            <tr key={ling._id}>
              <td className="tableLingText">{ling.lingua}</td>
        <td className="tableLingText">{ling.livascolt}</td>
        <td className="tableLingText">{ling.livlett}</td>
        <td className="tableLingText">{ling.livinter}</td>
        <td className="tableLingText" >{ling.livprod}</td>
        <td className="tableLingText">{ling.livprodscrt}</td>
       
        <td className="noone">
          <button
            onClick={() => deleteLingue(ling._id)}
            className="btn btn-danger"
          >
          <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
   
  
    ));
  
    }

  const deleteExperience= (_id) => {

    if (window.confirm('Sei sicuro?')) {
      // dispatch(getUserDetails(userInfo._id))
        dispatch(deleteUserExperience(_id))
        // dispatch(getUserDetails(userInfo._id))
       //dispatch(getUserDetails(userInfo._id))
        //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
        //dispatch({type: USER_UPDATE_PROFILE_RESET })
   
    }
    }

  var experiences =[];

  if(user.experience===undefined){
    
  }else{
  experiences = user.experience.map((exp) => (
    <tr key={exp._id}>
      <td>{exp.company}</td>
      <td >{exp.title}</td>
      <td>
        {formatDate(exp.from)} - {exp.to ? formatDate(exp.to) : 'Oggi'}
      </td>
      <td className="noone">
        <button
          onClick={() => deleteExperience(exp._id)}
          className="btn btn-danger"
        >
        <i class="fas fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  ));
  }

  const deleteEducation = (_id) => {

    if (window.confirm('Sei sicuro?')) {
      dispatch(getUserDetails(userInfo._id))
      //dispatch({type: USER_UPDATE_PROFILE_RESET })
        dispatch(deleteUserEducation(_id))
        dispatch(getUserDetails(userInfo._id))
       //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})

        

        //dispatch({type: USER_UPDATE_PROFILE_RESET })
    }
    }

  var educations =[];

  if(user.education===undefined){
    
  }else{
     educations = user.education.map((edu) => (
      <tr key={edu._id}>
        <td>{edu.school}</td>
        <td className="hide-sm">{edu.degree}</td>
        <td>
          {formatDate(edu.from)} - {edu.to ? formatDate(edu.to) : 'Oggi'}
        </td>
        <td className="noone">
          <button
            onClick={() => deleteEducation(edu._id)}
            className="btn btn-danger"
          >
          <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
  ));
  }


  const deletePayment = (_id) => {

    if (window.confirm('Sei sicuro?')) {
      dispatch(getUserDetails(userInfo._id))
        dispatch(deleteUserPayment(_id))
        dispatch(getUserDetails(userInfo._id))
      // dispatch(getUserDetails(userInfo._id))
        //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
        //dispatch({type: USER_UPDATE_PROFILE_RESET })
    }
    }

  var payments=[];
  if(user.payment===undefined){
    
  }else{
  payments = user.payment.map((pay) => (
    <tr key={pay._id}>
      <td>{pay.title}-{pay.saldo}€</td>
      
      
      <td>
        {formatDate(pay.from)} a <br/>{formatDate(pay.to)}
      </td>
      <td>
        <button  className="btn btn-success"
          onClick={() => downPay(pay.filecaricato)}
          
        ><i class="fas fa-download"></i></button>
        
        
        </td>
      
    </tr>
  ));
  }
  const deleteProject = (_id) => {

  if (window.confirm('Sei sicuro?')) {
    dispatch(getUserDetails(userInfo._id))
      dispatch(deleteUserProject(_id))
      dispatch(getUserDetails(userInfo._id))
      // dispatch(getUserDetails(userInfo._id))
        //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
        //dispatch({type: USER_UPDATE_PROFILE_RESET })
  }
  }
  var projects = [];

  if(user.project===undefined){

  }else{
    projects = user.project.map((proj) => (
      <tr key={proj._id}>
        <td>{proj.company}</td>
        <td >{proj.title}</td>
        <td>
          {formatDate(proj.from)} - {proj.to ? formatDate(proj.to) : 'Oggi'}
        </td>
        <td className="noone">
          <button
            onClick={()=>deleteProject(proj._id)}
            //onClick={() => deleteUserProject(proj._id)}
            className="btn btn-danger"
          >
          <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
 
  
  ));
 }

  

  return (
    <Fragment>
      <Header />
      
      {user.name !== undefined ? (
        <Fragment >
          <Sidebar curriculum={user.curriculum} dipendente={user.username || user.name }/>
          
          <h1 className="large text-primary">Dashboard</h1>
      <p className="lead">
        <i className="fas fa-user" /> Benvenuto  {user.name} {user.surname}{" "} <div hidden={user.curriculum=== undefined} ><button  className="btn btn-success"
          onClick={() => downCur(user.curriculum)}
          
       ><i class="fas fa-download"></i>{" "}<tb>Scarica CV</tb>

        </button>
        </div> 
      </p>
      
       
      
          <Evento  evento={user.evento}/>

          <Fragment >

    <div className="contdash">
      <h2 className="my-2">
      Percorso Lavorativo</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Azienda</th>
            <th >Titolo</th>
            <th >Anni</th>
            
          </tr>
        </thead>
        <tbody>
          {experiences}
          </tbody>
      </table>
      </div>
    </Fragment>
    <Fragment>
    <div  className="contdash">
      <h2 className="my-2">Percorso Formativo</h2>
      
      <table className="table">
      
        <thead>
          <tr>
            <th>Scuola / Università</th>
            <th >Grado</th>
            <th >Anni</th>
            
          </tr>
        </thead>
        <tbody>{educations}</tbody>
        
      </table>
      
      </div>
    </Fragment>
    <Fragment >
    <div  className="contdash">
      <h2 className="my-2">
      Pagamenti</h2>
      <table className="table">
        <thead>
          <tr >
            <th>Mese</th>
            {/* <th >Saldo</th> */}
            <th >Data</th>
            <th>File</th>
            
          </tr>
        </thead>
        <tbody>{payments}</tbody>
      </table>
      </div>
    </Fragment>
    <Fragment>
    <div className="contdash">
      <h2 className="my-2">
      Progetti</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Azienda</th>
            <th >Titolo</th>
            <th >Anni</th>
           
          </tr>
        </thead>
        <tbody>{projects}</tbody>
      </table>
      </div>
    </Fragment>
    <Fragment>
    <div  className="contdash">
      <h2 className="my-2">Lingue</h2>
      
      <table className="tableLing">
      
        <thead>
          <tr>
            <th>Lingua</th>
            <th>Ascolto</th>
            <th >Lettura</th>
            <th >Interazione</th>
            <th >Produzione</th>
            <th >Prod. Scritta</th>
            
          </tr>
        </thead>
        <tbody>{lingues}</tbody>
        
      </table>
      
      </div>
    </Fragment>
   {/* {  <Fragment>
    <div className="contdash">
      <h2 className="my-2">
      Curriculum</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Curriculum</th>
            
            <th />
          </tr>
        </thead>
        <tbody>{userInfo.curriculum}</tbody>
      </table>
      </div>
    </Fragment> } */}
      
      

         
        </Fragment>
      ) : (
        <Fragment>
          <p>Non hai ancora settato il tuo profilo...</p>
          <Link to="/create-profile" className="btn btn-primary my-1">
            Create Profile
          </Link>
        </Fragment>
      )}
    </Fragment>
  );
};



export default Dashboard;

