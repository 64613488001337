import React, { Fragment, useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'
import {  addUserLingueAdmin, getUserByid } from '../../../actions/userActions';
import Header from '../../layout/Header';

const AddLingueAdmin = ({ location, match, history }) => {
  const userId = match.params.id
  const [lingua, setLingua] = useState('')
  const [livascolt, setLivascolt] = useState('')
  const [livlett, setLivlett] = useState('')
  const [livinter, setLivinter] = useState('')
  const [livprod, setLivprod] = useState('')
  const [livprodscrt, setLivprodscrt] = useState('')

  const dispatch = useDispatch()
  

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  
  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid

  const userAddLingue = useSelector((state) => state.userAddLingue)
  const { success } = userAddLingue

  const redirect = location.search ? location.search.split('=')[1] : `/profile/${userId}`


 
  useEffect(() => {
    if (!user) {
      history.push('/login')
    } else {
      if (!user || !user.username || success) {
        
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserByid(userId))
        
   
      } 
    }
  }, [dispatch, history, userInfo, user, success])


const submitHandler = (e) => {
  e.preventDefault()
  dispatch(getUserByid(userId))
    dispatch(addUserLingueAdmin(userId, {  lingua, livlett, livascolt, livinter, livprod, livprodscrt}))
    //dispatch({ type: USER_UPDATE_SUCCESS })
    dispatch(getUserByid(userId))
      history.push(redirect)
    
}

  return (
    <Fragment>
      <Header />
      <h1 className="large text-primary">Agg. Lingua a {user.name} {user.surname}</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Aggiungi ogni posizione come programmator/developer ricoperta in passato
        
      </p>
      <small>* = campo richiesto</small>
      <form
        className="form"
        onSubmit={submitHandler}
      >
        <div className="form-group">
          <input
            type="text"
            placeholder="* Lingua "
            name="lingua"
            value={lingua}
            onChange={(e) => setLingua(e.target.value)}
            required
          />
        </div>
       

        <div className="form-group">
        <small className="form-text">
            Livello Comprensione - ascolto
          </small>
          <select name="genere" value={livascolt} onChange={(e) => setLivascolt(e.target.value)}>
            <option>* Seleziona Livello Comprensione - ascolto</option>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="B1">B1</option>
            <option value="B2">B2</option>
            <option value="C1">C1</option>
            <option value="C2">C2</option>
            
          </select>
         
        </div>
        <div className="form-group">
        <small className="form-text">
            Livello Comprensione - lettura
          </small>
          <select name="genere" value={livlett} onChange={(e) => setLivlett(e.target.value)}>
            <option>* Seleziona Livello Comprensione - lettura</option>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="B1">B1</option>
            <option value="B2">B2</option>
            <option value="C1">C1</option>
            <option value="C2">C2</option>
            
          </select>
         
        </div>
        <div className="form-group">
        <small className="form-text">
            Livello Parlato - interazione
          </small>
          <select name="genere" value={livinter} onChange={(e) => setLivinter(e.target.value)}>
            <option>* Seleziona Livello Parlato - interazione</option>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="B1">B1</option>
            <option value="B2">B2</option>
            <option value="C1">C1</option>
            <option value="C2">C2</option>
            
          </select>
         
        </div>
        <div className="form-group">
        <small className="form-text">
            Livello Parlato - produzione
          </small>
          <select name="genere" value={livprod} onChange={(e) => setLivprod(e.target.value)}>
            <option>* Seleziona Livello Parlato - produzione</option>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="B1">B1</option>
            <option value="B2">B2</option>
            <option value="C1">C1</option>
            <option value="C2">C2</option>
            
          </select>
         
        </div>
        <div className="form-group">
        <small className="form-text">
            Livello Produzione scritta 
          </small>
          <select name="genere" value={livprodscrt} onChange={(e) => setLivprodscrt(e.target.value)}>
            <option>* Seleziona Livello Produzione scritta </option>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="B1">B1</option>
            <option value="B2">B2</option>
            <option value="C1">C1</option>
            <option value="C2">C2</option>
            
          </select>
         
        </div>
        
        
        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to={`/profile/${userId}`}>
          Indietro
        </Link>
      </form>
    </Fragment>
  );
};



export default AddLingueAdmin;
