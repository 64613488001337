import React, { Fragment, useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'
import { getUserByid , addUserPaymentAdmin, addUserDocumentiAdmin } from '../../../actions/userActions';
import Header from '../../layout/Header';
import FileUpload from './FileUpload';
import axios from'axios';
const AddDocumentiAdmin2 = ({match, location, history, dipendente}) => {
   const userId = dipendente
  const [title, setTitle] = useState('')

  const [file, setFile] = useState(new File([" "], " "))
  const [filename, setFilename] = useState('Choose File');
  const [description, setDescription] = useState('')

  const dispatch = useDispatch()

 
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid

  const userAddPayment = useSelector((state) => state.userAddPayment)
  const { success } = userAddPayment

  // const redirect = location.search ? location.search.split('=')[1] : `/list-documenti/${userId}`


 
  useEffect(() => {
    if (!user) {
      history.push('/login')
    } else {
      if (!user || !user.username || success) {
       
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserByid(userId))
        
        window.location.reload( `/list-documenti/${user._id}`);

   
      } 
    }
  }, [dispatch, history, userInfo, user, success])


const submitnew = async e => {
 
 const pippo = document.getElementById('customFile').value

 if (pippo=== " "){
   return
 }
 if (pippo=== " "){
  return
}
 const formData = new FormData();
 formData.append('name',"documenti/"+file.name);
 formData.append('mimetype',file.type);
 formData.append('file', file);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    }
    
    // await axios.post(`https://api.gestionale.ipsedocet.com/api/users/${userId}/upload/payment`, formData, config, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }})
     console.log(file)
     console.log(formData)
     

     await axios.post(`https://api.gestionale.ipsedocet.com/bucket`, formData,config, {
  
    })

    // const { fileName, filePath } = res.data;

    // setUploadedFile({ fileName, filePath });

   alert('File Uploaded');
  } catch (err) {
    if (err.response.status === 500) {
      alert('There was a problem with the server');
    } else {
      console.log(file)
     
      alert("Qualcosa è andato storto")
     
      //alert(err.response.data.msg);
    }
  }
};



const onChange = e => {
  
  
  setFile(e);
  setFilename(e.name);
};
const submitHandler = (e) => {
  e.preventDefault()
  dispatch(getUserByid(userId))
 
  submitnew(file)

  const filecaricato = file.name;
  dispatch(getUserByid(userId))
    dispatch(addUserDocumentiAdmin(userId, {  title, filecaricato:filecaricato}))
    
    dispatch(getUserByid(userId))
    // history.push(`/list-documenti/${userId}`)
    // window.location.reload( `/list-documenti/${user._id}`);

    // window.location.reload(`/list-documenti/${userId}`)
    
}
  return (
    <Fragment>
      
      <h3 className="titolomodaleDoc text-primary">Agg. Documento a {user.name} {user.surname}</h3>
      <p className="lead1">
        <i className="fas fa-code-branch" /> Aggiungi Documento
        
      </p>
      
      <form
        className="form"
        onSubmit={submitHandler}
      >
        <div className="form-group1">
          <input
            type="text"
            placeholder="*Documento"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
       
       
        
       
       <div className="form-group1">
          <input
          required
            name="file"
            type="file"
            id='customFile'
            enctype='multipart/form-data'
           // value={file}
            onChange={(e) =>{onChange(e.target.files[0])}}
          />
  </div>
        <input type="submit" className="btnAdd btn-primary my-1" />
        
      </form>
    </Fragment>
  );
};



export default AddDocumentiAdmin2;
