import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants'
import axios from 'axios';

import { getUserDetails, updateUserProfile, updateUser } from '../../actions/userActions'
import Header from '../layout/Header';


const CurriculumEdit = ({ location, history }) => {
  /*const [username, setUsername] = useState(userInfo.username)
  const [email, setEmail] = useState(userInfo.email)
  const [isAdmin, setIsAdmin] = useState(userInfo.isAdmin)*/


 


  const [indirizzo, setIndirizzo] = useState('')
  const [hobby, setHobby] = useState([])
 const [competenzedig, setCompetenzedig] = useState([])
 const[curriculum, setCurriculum]=useState('')
 const [file, setFile] = useState(new File([" "], ""))
 const [filename, setFilename] = useState('Choose File');
 const [email2, setEmail2] = useState('')




  const dispatch = useDispatch()



  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const redirect = location.search ? location.search.split('=')[1] : '/dashboard'
  const redirect2 = location.search ? location.search.split('=')[1] : '/login'

  console.log(userInfo)

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!userInfo || !userInfo.username || success) {


        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails(userInfo._id))


      }
    }
  }, [dispatch, history, userInfo, user, success])
  console.log(userInfo)

  const submitHandler = (e) => {
    e.preventDefault()
     const a = document.getElementById('hobby').value || document.getElementById('hobby').placeholder
  const hobby1 = a.split(',');
 const b = document.getElementById('competenzedig').value || document.getElementById('competenzedig').placeholder
 const competenzedig2= b.split(',')
 if(file!==undefined){
  submitnew(file)
    }
 const filecaricato = file.name;
 const curriculum = filecaricato
    dispatch(updateUserProfile({ id: userInfo._id, username: userInfo.username, email: userInfo.email, isAdmin: userInfo.isAdmin,  indirizzo, hobby: hobby1, competenzedig: competenzedig2, curriculum:curriculum, email2 }))
    dispatch(getUserDetails(userInfo._id))

    //window.location.reload(`/profile/${user.id}`)
    history.push(redirect)
  }

  const submitnew = async e => {
 
    const pippo = document.getElementById('customFile').value
    if (pippo=== ""){
      return
    }
    if (pippo=== ""){
     return
   }
    const formData = new FormData();
    formData.append('name',`curriculum/`+file.name);
    formData.append('mimetype',file.type);
    formData.append('file', file);
     try {
       const config = {
         headers: {
           Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
           'Access-Control-Allow-Origin' : '*',
           'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
         },
       }
       
       // await axios.post(`https://api.gestionale.ipsedocet.com/api/users/${userId}/upload/payment`, formData, config, {
       //   headers: {
       //     'Content-Type': 'multipart/form-data'
       //   }})
        console.log(file)
        console.log(formData)
        
   
        await axios.post(`https://api.gestionale.ipsedocet.com/bucketCur`, formData, config, {
     
       })
   
       // const { fileName, filePath } = res.data;
   
       // setUploadedFile({ fileName, filePath });
   
      alert('File Uploaded');
     } catch (err) {
       if (err.response.status === 500) {
         alert('There was a problem with the server');
       } else {
         console.log(file)
         alert(err.response.data.msg);
       }
     }
   };

   const onChange = e => {
 
    if (e=== undefined){
      return
    }
    setFile(e);
    setFilename(e.name);
  };  
  

  return (
    <Fragment>
      <Header />
      <h1 className="large text-primary">Modifica CV</h1>
      <p className="lead">
        <i className="fas fa-user" /> Aggiungi informazioni
      </p>
      <small>* = campi richiesti</small>
      <form className="form" onSubmit={submitHandler}>
        {/* <div className="form-group">
          <input
            type="text"
            placeholder={userInfo.name}
            name="nome"
            value={name } 
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={userInfo.surname}
            name="surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </div> */}

<div className="form-group">
        <label>
            <h4>Email</h4>

            </label>
          <input
            type="email"
            placeholder={user.email2 ?? "Inserisci email" } 
            name="email"
            value={email2}
            onChange={(e) => setEmail2(e.target.value)}
          />
         
        </div>

        <div className="form-group">
          <label>
            <h4>Indirizzo</h4>
          </label>
          <input
            type="text"
            placeholder={userInfo.indirizzo || "Indirizzo"}
            name="indirizzo"
            value={indirizzo}
            onChange={(e) => setIndirizzo(e.target.value)}
          />
          <small className="form-text">
            Scrivi il tuo indirizzo --- es* Via Roma, 1 , Roma (RM)
</small>
        </div>
       
          <div className="form-group">
            <label>
            <h4>Competenze Digitali</h4>

            </label>
          <input
            type="text"
            placeholder={userInfo.competenzedig || "Competenze Digitali" }
            name="competenzedig"
            value={competenzedig}
            onChange={(e) => setCompetenzedig(e.target.value)}
            id="competenzedig"
          />
          <small className="form-text">
            Per favore usa la virgola per separare i valori (es. HTML Buono,CSS eccellente, JavaScript ottimo, PHP ottimo)
</small>
          <small className="form-text">
            Legenda (Sufficiente, Discreto, Buono, Eccellente)
</small>
        </div>

         <div className="form-group">
           <label>
             <h4>Ulteriore informazione Hobby</h4>
           </label>
          <input
            type="text"
            placeholder={userInfo.hobby ?? "Ulteriore informazione Hobby"}
            name="hobby"
            value={hobby}
            onChange={(e) => setHobby(e.target.value)}
            id="hobby"
          />
          <small className="form-text">
            Per favore usa la virgola per separare i valori (es. Suonare la chitarra, etc,)
</small>
        </div>  
        <div className="form-group">
           <label>
             <h4>Upload CV</h4>
           </label>
           <input
            name="file"
            type="file"
            id='customFile'
            enctype='multipart/form-data'
           // value={file}
            onChange={(e) =>{onChange(e.target.files[0])}}
          />
          
        </div>  
        

        <input type="submit" className="btn btn-primary my-1" />

        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};


export default CurriculumEdit;
