import React , { useRef, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import { getUserDetails} from '../../../actions/userActions';
import Sidebar from '../../dashboard/Sidebar';

import Header from '../../layout/Header';
import ComponentToPrint2 from './ComponentToPrint2';


const Print2 = ({match, history}) => {

  const dispatch = useDispatch()
  
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails
  const pippo = userInfo._id
 
 
    const componentRef = useRef();


    const handlePrint = useReactToPrint({
      
      content: () => componentRef.current,
    });
    console.log(userInfo)
    useEffect(() => {
      if (!userInfo) {
        history.push('/login')
      } else {
        if (!user || !user.username ) {
          
        //  
         console.log(userInfo._id)
          dispatch(getUserDetails(userInfo._id))
          
     
        } 
      }
    }, [])
    
    return (
      <div>
        <Header/>
        <Sidebar/>
        <div className="contdashPrint">
          
          <ComponentToPrint2 test={pippo} ref={componentRef} />
          <div>
        <button className="btnEstrai" onClick={handlePrint}>Salva CV {' '}<i class="fas fa-file-pdf"></i></button>
        </div>
        </div>
        
        <div>
          
        </div>
      </div>
    );
  };
  export default Print2;