import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {USER_BYID_FAIL, USER_BYID_REQUEST, USER_BYID_SUCCESS, USER_LIST_SUCCESS, USER_LOGIN_SUCCESS} from '../../constants/userConstants';
import {USER_BYID_RESET} from '../../constants/userConstants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import ProfileItem from './ProfileItem';
import Sidebar from '../dashboard/Sidebar';
import SidebarAdmin from '../Admin/SidebarAdmin';
import { Link } from 'react-router-dom';

import { listUsers, deleteUser,login } from '../../actions/userActions'
import Header from '../layout/Header';


const Profiles = ({ history }) => { 
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm]= useState('')
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userList = useSelector((state) => state.userList)
  const { loading, error, users } = userList
 // console.log(userList)
  
 

  
  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete } = userDelete


  
  useEffect(() => { 
    
    if (userInfo && userInfo.isAdmin) {
      
      dispatch(listUsers(users))
       
      
  
      
    } else {
      history.push('/login')
    }
  }, [dispatch, history, successDelete, userInfo])
  
  const pippo = () => {
    
    window.location=('/profiles')
    dispatch({
      type: USER_BYID_RESET,
    })
  }

  
  const deleteHandler = (_id) => {
  
    if (window.confirm('Sei sicuro di voler Eliminare?')) {
      if (window.confirm('Sei proprio sicuro di voler Eliminare?')) {
        dispatch(deleteUser(_id))
        
        //history.push('/profiles')
    }
    }
  }
  
  return (
    
   
  <Fragment>
    <Header />
    <div className="sidebar">
<ul >
<div className="divsid" >
<li >
<Link className="sidebarlist" to={``} >
<i className='fas fa-user-circle ' /> <t className="hide-sm">Profilo</t>
</Link>
</li>
</div>
<div className="divsid" >
<li>
<Link className="sidebarlist" onClick={()=>pippo()}  >
<i class="fas fa-users"></i> <t className="hide-sm">Dipendenti </t>
</Link>
</li>
</div>
<div className="divsid" >
<li>
<Link className="sidebarlist" to="/list-curriculum" >
<i class="fas fa-users"></i> <t className="hide-sm">Lista CV </t>
</Link>
</li>
</div>
</ul>
</div>
      {loading ? (
        <Spinner />
        ) : (
        <Fragment>
        
          <h1 className='large text-primary'>Dipendenti</h1>
          <div> <input className="barraricerca" placeholder="Cerca..." onChange={event =>{setSearchTerm(event.target.value)}}/></div>
          <p className='lead'>
            <i className='fab fa-connectdevelop' /> Connesso con Gestionale
            Ipse
          </p>
          <div className=''>
            
            {users.length > 0  ? (
              users.filter((val)=>{
             
try {
  if (searchTerm == ""){
    return val
  }else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())){
    return val
  }
else if (val.surname.toLowerCase().includes(searchTerm.toLowerCase())){
  return val
}
} catch (error) {
  
}

            
              // else if (val.skills[""].toLowerCase().includes(searchTerm.toLowerCase())){
              //   return val
              // }
              
              }).map((val , users) => (
                <div hidden={val.name===undefined} className='profileitems'>
     
                <div >
                  <p className="deleteUserX" onClick={() => deleteHandler(val._id)}><i class="fas fa-trash-alt"></i></p>
                  <h2>  {val.name}{' '}{val.surname}</h2>
                  <p>
                    {val.status} {val.company && <span> presso {val.company}</span>}
                  </p>
                  <p>{val.situazione}</p>
                  <p>
                    {val.skills + " - "  } 
                  </p>
                  <p className='my-1'>{users.location && <span>{val.location}</span>}</p>
                  <Link to={`/profile/${val._id}` } /*onClick={getUserByid(val._id)}*/ className='btn btn-primary'>
                    Mostra Profilo
                  </Link>
                </div>
               
              </div>
              ))
            ) : (
              <h4> Nessun profilo trovato...</h4>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};



export default Profiles;
