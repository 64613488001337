import React, { Fragment, useEffect,useState  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import ProfileExperience from './ProfileExperience';
import ProfileEducation from './ProfileEducation';
import ProfileProject from './ProfileProject';
import ProfilePayment from './ProfilePayment';
import {getUserDetails,deleteUser, getUserByid ,deleteUserExperienceAdmin, deleteUserEducationAdmin, deleteUserProjectAdmin, deleteUserPaymentAdmin} from '../../actions/userActions'
import formatDate from '../../utils/formatDate';
import Evento from '../dashboard/Evento';
import Sidebar from '../dashboard/Sidebar';
import SidebarAdmin from '../Admin/SidebarAdmin';
import Header from '../layout/Header';
import EventoAdmin from '../Admin/sidebarAdmin/EventoAdmin';
import TabDocumenti from '../Admin/sidebarAdmin/TabDocumenti';
import { USER_BYID_RESET } from '../../constants/userConstants';
import AddDocumentiAdmin2 from '../Admin/sidebarAdmin/AddDocumentAdmin2';
import Modal from 'react-modal';




const ListDocumenti = ({match,  history, location }) => {
  
  
  const dispatch = useDispatch()
  const userId = match.params.id
  //const userLogin = useSelector((state) => state.userLogin)
  //const { userInfo } = userLogin
  const [modalIsOpen, setModalIsOpen] = useState(false) 

  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid
  console.log(user)
  const deleteHandler = (_id) => {
  
    if (window.confirm('Sei sicuro?')) {
      dispatch(deleteUser(_id))
      
      history.push('/profiles')
    }
  }

  
  const pippo = () => {
    
    window.location=('/profiles')
    dispatch({
      type: USER_BYID_RESET,
    })
  }
  

  useEffect(() => {
    if (!user ?? user.isAdmin ) {
      history.push('/login')
    } 
    else {
      
      if (!user || !user.name ) {
        
        dispatch(getUserByid(userId))
        //history.push(`/profile/${userId}`)
        console.log('****')
        console.log(user)

        dispatch(getUserByid(userId))


        
       
  
      } 
    }
  }, [dispatch,user, history,userId,  /*userInfo*/ ])
  
  



  return (
    <Fragment>
    <Header />
    <div >
    <div className="sidebar">
        
        
        <ul >
        <div className="divsid" >
        <li >
        <Link className="sidebarlist"  to={`/profile/${userId}`} >
        <i className='fas fa-user-circle ' /> <t className="hide-sm">Profilo</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   onClick={()=>pippo()} >
        <i class="fas fa-users"></i> <t className="hide-sm">Dipendenti </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   to="/list-curriculum" >
        <i class="fas fa-users"></i> <t className="hide-sm">Lista CV </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   to={`/edit-profile/${userId}`} >
        <i className='fas fa-cogs' /> <t className="hide-sm">Edit </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to={`/add-experience/${userId}`}>
          <i className='fab fa-black-tie ' /> <t className="hide-sm">Lavoro </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to={`/add-education/${userId}`} >
          <i className='fas fa-graduation-cap ' /> <t className="hide-sm">Studi </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/add-project/${userId}`}>
          <i className='fas fa-briefcase ' />  <t className="hide-sm">Progetto </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to= {`/add-payment/${userId}`}>
          <i className='fas fa-credit-card ' /> <t className="hide-sm">Pagamento </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to= {`/add-lingue/${userId}`}>
        <i class="fas fa-language"/> <t className="hide-sm">Lingue </t>
        </Link>
        </li>
        </div>
         <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/curriculum/${userId}`}>
        <i class="far fa-file"/>  <t className="hide-sm">Curriculum </t>
        </Link>
        </li>
        </div> 
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-documenti/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Documenti</t>
        </Link>
        </li>
        </div>

        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-pagamenti/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Estract Pay</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-event/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Estract Eve </t>
        </Link>
        </li>
        </div>
        <div className="divsid">
          <div className="Bottoneeliminauser">
            <button className="btn btn-danger" onClick={() => deleteHandler(user._id)}>
              <i className="fas fa-user-minus" /> <t className="hide-sm">{' '}Delete Account</t>
            </button>
            </div>
          </div>
       
       
        </ul>
        </div>
        </div>
        <div className='contdash'>
        <div>
        <Modal 
     className='ModalEvent'
     isOpen={modalIsOpen} 
     onRequestClose={()=> setModalIsOpen(false)}
     style={
      {
        
       
 
       overlay:{
         zIndex:'100 ',
        opacity:'100%'
      },
       content:{
         color: '#fff',
         backgroundColor:' #0077ff',
         width:'400px',
         height:'310px',
         marginLeft: '35%',
         marginTop:'2%',
         marginBottom:'50px',
         position:'inherit',
         opacity:'100%',
         borderRadius:'30px',
         zIndex:'100 ',
         
       }
     }
     }
     >
 <p onClick={()=>setModalIsOpen(false)} className='chiusuraModal'>X</p>
     <AddDocumentiAdmin2 dipendente={match.params.id}/> 
     
     
     </Modal>
        </div>
        <div className="contAdd">
        <button className="btnAddDoc" onClick={()=>setModalIsOpen(true)}>Aggiungi Documento</button>
        </div>
    {user.name !== undefined ? (
      <Fragment>
    
<TabDocumenti dipendente={userId}/>

            
     
      </Fragment>
    
    ) : (
    <Fragment>
      <Spinner />
      
    </Fragment>
    )}</div>
     </Fragment>
     
    );
  };




  

export default ListDocumenti;
