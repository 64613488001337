import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import formatDate from '../../../utils/formatDate';
import FullCalendar,{ customButtons, render} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import Modal from 'react-modal';

import {deleteUserEvento, deleteUserEventoAdmin, getUserDetails,getUserByid, getUserEventoAdmin,  } from '../../../actions/userActions';
import AddEventoAdmin from './AddEventoAdmin';
import TabEvent from './TabEvent';
import AddBlocco from './AddBlocco';
import EditEventoAdmin from './EditEventoAdmin';
import axios from 'axios'
import { EVENTO_DETAILS_SUCCESS } from '../../../constants/userConstants';



const EventoAdmin = ({match, evento, dipendente }) => {
  var elimina=false;
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid
 

  const userDeleteEvento = useSelector((state) => state.userDeleteEvento)
  const { successDeleteEvento } = userDeleteEvento
  const bloccato = "2021-03-05"/*document.getElementById("BLOCCATO").value*/
  //const porcodio = eventInfo.event.extendedProps._id
  const deleteEvento= (_id) => {
    elimina=true;
    
    if (window.confirm('Sei Sicuro?')) {
      // dispatch(getUserByid(dipendente))
        dispatch(deleteUserEventoAdmin(dipendente,_id))
        dispatch(getUserByid(dipendente))
 
    }
    dispatch(getUserByid(dipendente))
    }
    // function CAPIRAI(info) {
    //   alert('Event: ' + info.event.title);
    //   alert('id: ' + info.event.extendedProps._id);
    //   const pippoEvento = info.event.extendedProps._id
    //   alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
    //   alert('View: ' + info.view.type);
  
    //   // change the border color just for fun
    //   info.el.style.borderColor = 'red';
      
    // } 

    
// const cambioID = (info)=>{
//   alert("AIUTO")
//   alert("AIUTO" + provaccia)
//         setProvaccia ((info.event.extendedProps._id))
        
//       }
function EditEVENTO(info, data) {
  // dispatch(getUserEvento(info.event.extendedProps._id))
  // dispatch({type:EVENTO_DETAILS_SUCCESS,
  //   payload:eventodata})
  setTimeout(() => {
    if(elimina){
      return
    }
// alert("ciaso")
// dispatch(getUserDetails(userInfo._id))
    const a =info.event._instance.range.start.toISOString()
    
    const giornoEvento = a.slice(0,10) 
    
    // dispatch(getUserEvento(info.event.extendedProps._id))
  
    // dispatch({type:EVENTO_DETAILS_SUCCESS,
    //   payload:eventodata})
    //    console.log(eventodata)
    //   console.log({payload:eventodata})
    setIdEvento(info.event.extendedProps._id) 
    //  setTitolo(eventodata.title)
    //  setNore(eventodata.ore) 
    //  setGdate(eventodata.data)
    
    //  setStarteve(eventodata.start) 
    //  setEndeve(eventodata.end)
    //  setNorepermessi(eventodata.orepermessi)
    //  setNoreferie(eventodata.oreferie)
    //  setNoremutua(eventodata.oremutua)
    //  setNorestraordinario(eventodata.orestraordinario)
    //  setTipoevento(eventodata.tipoevento)
    //  setGnote(eventodata.note)
          // alert("AIUTO")
          // alert("AIUTO" + provaccia)
          //       setProvaccia((info.event.extendedProps._id))
                
              
          // dispatch(USER_UPDATE_REQUEST)  
     setModalIsOpenEdit(true)
  }, 10);
  // console.log(info.jsEvent)
  
  // info.jsEvent.stopPropagation()


  }  
      // console.log('####', eventInfo.event._def.extendedProps)
      // alert('Event: ' + info.event.title);
      // alert('id: ' + info.event.extendedProps._id);
      // const [gino, setGino]= useState(pippoEvento)
      // const pippoEvento = info.event.extendedProps._id
      // alert(pippoEvento)
      // alert('provaccia: '+ provaccia)
      
    
      // alert(pippoEvento + "PRIMA")
      
      
      // alert(provaccia + "ppppppp")
      // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      // alert('View: ' + info.view.type);
     
      // change the border color just for fun
      // info.el.style.borderColor = 'red';
 



  function renderEventContent(eventInfo) {
    // console.log('####', eventInfo.event._def.extendedProps)

     
    return (
   
      <div
      className="contCal"
      style={ 
        {
          
          background:' #0077ff',         
          paddingTop:'1px',
          paddingBottom:'10px',
          paddingLeft:'1%',
          height:'130px',
          marginBottom:'1px',
          width:'100%',
          zIndex:'100',
          marginTop:'10%'
          
        }
      }
      >
        <div >
        <div>
         
        <p className="deleteEvento" onClick={(e)=>deleteEvento(eventInfo.event.extendedProps._id, e)} >X</p>
        <h3 style={
          {
            color: 'black',
            paddingLeft:"4%"
          }
        }>{eventInfo.event.title.substring(0,10)}</h3>
        </div>
        <div>
        <h5
        style={
          {
            fontSize:'0.8rem',
            color: 'white ',
            paddingLeft:"4%"
          }
        }
        >Lavorate: {eventInfo.event.extendedProps.ore}</h5>
        <h6 style={
          {
            fontSize:'0.8rem',
            color: 'white ',
            paddingLeft:"4%"
          } }> Straordinario: {eventInfo.event.extendedProps.orestraordinario}</h6> 
          <h6 style={
            {
              fontSize:'0.8rem',
              color: 'white ',
              paddingLeft:"4%"
            } }>Ferie: {eventInfo.event.extendedProps.oreferie}</h6> <h6 style={
              {
                fontSize:'0.8rem',
                color: 'white ',
                paddingLeft:"4%"
              } }> Mutua: {eventInfo.event.extendedProps.oremutua}</h6><h6 style={
                {
                  fontSize:'0.8rem',
                  color: 'white ',
                  paddingLeft:"4%"
                } }> Permessi: {eventInfo.event.extendedProps.orepermessi}</h6>

{/* <h6 style={
                {
                  fontSize:'0.8rem',
                  color: 'white ',
                  paddingLeft:"4%"
                } }> NOTE: {eventInfo.event.extendedProps.note}</h6> */}

        </div>
        
        <p>  {eventInfo.event.extendedProps.start}</p>
        <p>  {eventInfo.event.extendedProps.end}</p>
        

        
        
        
        
        
        </div>
       
      </div>
    )

    }
   const apritiSesamo = (e)=>{
      setGiornoData((e.dateStr))

      setmodalIsOpen1(true)
    }
// const [provaccia, setProvaccia]=useState('agora')
const [idUser, setIdUser]=useState('');
const [idEvento, setIdEvento ]=useState('');

const [titolo, setTitolo ]=useState('');
const [nore, setNore]=useState('');
const [gdate, setGdate]=useState('');
const [starteve,setStarteve] =useState('');
const [endeve, setEndeve]=useState(''); 
const [norepermessi, setNorepermessi] =useState('');
const [noreferie, setNoreferie]=useState(''); 
const [noremutua, setNoremutua] =useState('');
const [norestraordinario, setNorestraordinario] =useState('');
const [gtipoevento, setTipoevento] =useState('');
const [gnote, setGnote] =useState('');

const [giornoData,setGiornoData]=useState('prova');
const [modalIsOpen1, setmodalIsOpen1] = useState(false) 
const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false)
const [modalIsOpenBlocca, setmodalIsOpenBlocca] = useState(false)  


  return (
    
   
    
    <div className="contCalendar">
    
   
    <Modal
    isOpen={modalIsOpenBlocca} 
    onRequestClose={()=> setmodalIsOpenBlocca(false)}
    style={
      {
        
       
 
       overlay:{
         zIndex:'100 ',
        opacity:'100%'
      },
       content:{
         color: '#fff',
         backgroundColor:' #0077ff',
         width:'350px',
         height:'300px',
         marginLeft: '35%',
         marginTop:'2%',
         marginBottom:'50px',
         position:'inherit',
         opacity:'100%',
         borderRadius:'30px',
         zIndex:'100 ',
         
       }
     }
     }
    >
      
    <p onClick={()=>setmodalIsOpenBlocca(false)} className='chiusuraModal'>X</p>
   <AddBlocco className="modalCont"/>
 </Modal>

    
  
    <Modal 
    className='ModalEvent'
    isOpen={modalIsOpen1} 
    onRequestClose={()=> setmodalIsOpen1(false)}
    style={
     {
       
      

      overlay:{
        zIndex:'100 ',
       opacity:'100%'
     },
      content:{
        color: '#fff',
        backgroundColor:' #0077ff',
        width:'400px',
        height:'610px',
        marginLeft: '35%',
        marginTop:'2%',
        marginBottom:'50px',
        position:'inherit',
        opacity:'100%',
        borderRadius:'30px',
        zIndex:'100 ',
        
      }
    }
    }
    >
<p onClick={()=>setmodalIsOpen1(false)} className='chiusuraModal'>X</p>
    <AddEventoAdmin className="modalCont" giorno={giornoData}/>
    
    </Modal>
     
    
     <Modal 
     className='ModalEvent'
     isOpen={modalIsOpenEdit} 
     onRequestClose={()=> setModalIsOpenEdit(false)}
     style={
      {
        
       
 
       overlay:{
         zIndex:'100 ',
        opacity:'100%'
      },
       content:{
         color: '#fff',
         backgroundColor:' #0077ff',
         width:'400px',
         height:'610px',
         marginLeft: '35%',
         marginTop:'2%',
         marginBottom:'50px',
         position:'inherit',
         opacity:'100%',
         borderRadius:'30px',
         zIndex:'100 ',
         
       }
     }
     }
     >
 <p onClick={()=>setModalIsOpenEdit(false)} className='chiusuraModal'>X</p>
     <EditEventoAdmin className="modalCont" idEvento={idEvento} titolo={titolo} nore={nore} starteve={starteve} endeve={endeve} norepermessi={norepermessi} noreferie={noreferie} noremutua={noremutua} norestraordinario={norestraordinario} gtipoevento={gtipoevento} gnote={gnote}/*idEvento={eventInfo.event.extendedProps._id}*/ idUser={user._id}  giorno={gdate}/>
     
     </Modal>
    
    <div className="contenitoreCal">

    <FullCalendar
    style={
      {
        width:'400px',
        zIndex:'1'
    }}
    validRange={{start:user.blocco}}
   
    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
    initialView="dayGridMonth"
    
    headerToolbar={{
      left: 'prev,next today blocca',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
      // <button className="bloccobtn" onClick={()=>setmodalIsOpenBlocca(true)}><i class="fas fa-unlock-alt"></i> Blocca </button>

    }}
    customButtons= {{
      blocca: {
        text: 'Blocca',
        click: function() {
          setmodalIsOpenBlocca(true);
        }
      }
    }}

    customButtons2= {{
      prova: {
        text: 'prova',
        click: function() {
          setmodalIsOpenBlocca(true);
        }
      }
    }}
    
    firstDay={1}
    locale='it'
    selectable={true}
    events={evento}
    // events={[
    //   { title: 'event 1',  start:'2021-01-21', ore:'8',  end:'2021-01-21' },
    //   { title: 'LAVORATO', start: '2021-01-05T15:00:00', end: '2021-01-05T18:00:00'  }
    // ]}
    eventContent={renderEventContent}
    editable={false}
    // dateClick={()=>setmodalIsOpen(true)}
    dateClick={(e)=>apritiSesamo(e)}
    eventClick={(info)=>{
    // cambioID(info) 
    EditEVENTO(info);
     
    }}
    //all-day={false}
    />
    
    </div>
   
   </div>
  );
  
};






export default EventoAdmin;
