import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails} from '../../actions/userActions'
import {USER_BYID_FAIL, USER_BYID_RESET, USER_LIST_SUCCESS} from '../../constants/userConstants';


import { logout } from '../../actions/userActions';


const Header = ({location, history}) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails


  const pippo = () => {
    
    window.location=('/profiles')
    dispatch({
      type: USER_BYID_RESET,
    })
  }

  const logoutHandler = () => {
    dispatch(logout())
  }

   return (
    <nav className="navbar bg-dark">
      <h1>
        <Link to="/">
          <i className="fas fa-code" /> Gestionale Ipse 
        </Link>
      </h1>
      {userInfo  ? (
        <Fragment>
        <ul>

      <li>
        <Link to="/dashboard">
          <i className="fas fa-user" />{' '}
          <span className="hide-sm">{userInfo.name}</span>
        </Link>
      </li>
      <li>
        <a onClick={logoutHandler} href="/">
          <i className="fas fa-sign-out-alt" />{' '}
          <span className="hide-sm">Logout</span>
        </a>
      </li>
    </ul>
    </Fragment>
        ) : (
          <Fragment>
          <ul>
          
          <li>
            <Link to="/register">Register</Link>
          </li>
          <li>
            <Link to="/login">Login</Link>
          </li>
        </ul>
        </Fragment>
      )}
      {userInfo && userInfo.isAdmin && (
        
        <ul>
      <li>
        <Link onClick={()=>pippo()} ><i class="fas fa-users"></i> Dipendenti</Link>
      </li>
      
      
    </ul>
    
        ) 
     
      }

    </nav>
  );
};



export default Header;
