import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,

  USER_BYID_REQUEST,
  USER_BYID_SUCCESS,
  USER_BYID_FAIL,
  USER_BYID_RESET,

  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,

      USER_LOGIN_FAIL,
      USER_LOGIN_REQUEST,
      USER_LOGIN_SUCCESS,
      USER_LOGOUT,

      USER_REGISTER_FAIL,
      USER_REGISTER_REQUEST,
      USER_REGISTER_SUCCESS,

  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,

  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,


      LINGUE_DELETE_REQUEST,
      LINGUE_DELETE_SUCCESS,
      LINGUE_DELETE_FAIL,

      EXPERIENCE_DELETE_REQUEST,
      EXPERIENCE_DELETE_SUCCESS,
      EXPERIENCE_DELETE_FAIL,

         

      EDUCATION_DELETE_REQUEST,
      EDUCATION_DELETE_SUCCESS,
      EDUCATION_DELETE_FAIL,

      EVENTO_DELETE_REQUEST,
      EVENTO_DELETE_SUCCESS,
      EVENTO_DELETE_FAIL,

      EVENTO_DETAILS_REQUEST,
      EVENTO_DETAILS_SUCCESS,
      EVENTO_DETAILS_FAIL,
      EVENTO_DETAILS_RESET,

      EVENTO_DETAILS_USER_REQUEST,
      EVENTO_DETAILS_USER_SUCCESS,
      EVENTO_DETAILS_USER_FAIL,
      EVENTO_DETAILS_USER_RESET,

      PROJECT_DELETE_REQUEST,
      PROJECT_DELETE_SUCCESS,
      PROJECT_DELETE_FAIL,

      PAYMENT_DELETE_REQUEST,
      PAYMENT_DELETE_SUCCESS,
      PAYMENT_DELETE_FAIL,


      DOCUMENTI_DELETE_REQUEST,
      DOCUMENTI_DELETE_SUCCESS,
      DOCUMENTI_DELETE_FAIL,

  USER_UPDATE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,

  USER_UPDATE_PROFILE_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case USER_DETAILS_RESET:
      return { user: {} }
    default:
      return state
  }
}


export const userByidReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_BYID_REQUEST:
      return { ...state, loading: true }
    case USER_BYID_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_BYID_FAIL:
      return { loading: false, error: action.payload }
    case USER_BYID_RESET:
      return { user: {} }
    default:
      return state
  }
}


export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userAddLingueReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const userAddLingueAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userDeleteLingueReducer = (state = {}, action) => {
  switch (action.type) {
    case LINGUE_DELETE_REQUEST:
      return { loading: true }
    case LINGUE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case LINGUE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDeleteLingueAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case LINGUE_DELETE_REQUEST:
      return { loading: true }
    case LINGUE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case LINGUE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}





export const userAddExperienceReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const userAddExperienceAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userDeleteExperienceReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_DELETE_REQUEST:
      return { loading: true }
    case EXPERIENCE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case EXPERIENCE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDeleteExperienceAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_DELETE_REQUEST:
      return { loading: true }
    case EXPERIENCE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case EXPERIENCE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userAddEducationReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const userAddEducationAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userDeleteEducationReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATION_DELETE_REQUEST:
      return { loading: true }
    case EDUCATION_DELETE_SUCCESS:
      return { loading: false, success: true }
    case EDUCATION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDeleteEducationAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATION_DELETE_REQUEST:
      return { loading: true }
    case EDUCATION_DELETE_SUCCESS:
      return { loading: false, success: true }
    case EDUCATION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}



export const userAddEventoReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const userAddEventoAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userEditEventoAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userEditEventoReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}


export const userDeleteEventoReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTO_DELETE_REQUEST:
      return { loading: true }
    case EVENTO_DELETE_SUCCESS:
      return { loading: false, success: true }
    case EVENTO_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDeleteEventoAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTO_DELETE_REQUEST:
      return { loading: true }
    case EVENTO_DELETE_SUCCESS:
      return { loading: false, success: true }
    case EVENTO_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}


export const userGetEventoReducer = (state = { eventodata: {} }, action) => {
  switch (action.type) {
    case EVENTO_DETAILS_USER_REQUEST:
      return { ...state, loading: true }
    case EVENTO_DETAILS_USER_SUCCESS:
      return { loading: false, eventodata: action.payload }
    case EVENTO_DETAILS_USER_FAIL:
      return { loading: false, error: action.payload }
    case EVENTO_DETAILS_USER_RESET:
      return { eventodata: [] }
    default:
      return state
  }
}

export const userGetEventoAdminReducer = (state = { eventodata: {} }, action) => {
  switch (action.type) {
    case EVENTO_DETAILS_REQUEST:
      return { ...state, loading: true }
    case EVENTO_DETAILS_SUCCESS:
      return { loading: false, eventodata: action.payload }
    case EVENTO_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case EVENTO_DETAILS_RESET:
      return { eventodata: [] }
    default:
      return state
  }
}

export const userAddPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}


export const userAddPaymentAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userDeletePaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_DELETE_REQUEST:
      return { loading: true }
    case PAYMENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PAYMENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}


export const userDeletePaymentAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_DELETE_REQUEST:
      return { loading: true }
    case PAYMENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PAYMENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}


export const userAddDocumentiAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userDeleteDocumentiAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCUMENTI_DELETE_REQUEST:
      return { loading: true }
    case DOCUMENTI_DELETE_SUCCESS:
      return { loading: false, success: true }
    case DOCUMENTI_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}



export const userAddProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userAddProjectAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}
export const userDeleteProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case PROJECT_DELETE_REQUEST:
      return { loading: true }
    case PROJECT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PROJECT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDeleteProjectAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case PROJECT_DELETE_REQUEST:
      return { loading: true }
    case PROJECT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PROJECT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true }
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    case USER_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {
        user: {},
      }
    default:
      return state
  }
}


export const userUpdateBloccoReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {
        user: {},
      }
    default:
      return state
  }
}

