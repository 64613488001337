import React, { Fragment, useEffect,  useState  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getUserByid, getUserDetails } from '../../actions/userActions'
import formatDate from '../../utils/formatDate';
import Spinner from '../layout/Spinner';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { USER_DETAILS_RESET, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS } from '../../constants/userConstants';




const TabEventUser = ({dipendente }) => {
   

    const dispatch = useDispatch()

    
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails
  const [searchTerm, setSearchTerm]= useState('')
  const [anno, setAnno] = useState('')
  const [mese, setMese] = useState('')
  const pippo = anno +  mese
    useEffect(() => {
        if (!user) {
          window.location.push('/login')
        } 
        else {
          
          if (!user || !user.name ) {
            //  dispatch(USER_DETAILS_RESET
            // )
            dispatch(getUserDetails(user._id))
           
            // console.log(user)
            // //history.push(`/profile/${userId}`)
            // console.log('****')
            // console.log(user)
    
            dispatch(getUserDetails(userInfo._id))
    
    
            
           
      
          } 
        }
      }, [dispatch, userInfo,user  /*userInfo*/ ])

      const evento = user.evento
      const filenome = user.name + "."+ user.surname + formatDate( Date.now());
    //   const submitHandler = (e) =>{
 
        
    // let a = document.getElementById('anno').value
    // let b = document.getElementById('mese').value

    // const pippo = a + "-" + b +"-"
           
    //       }
   
    
     
    //     {users.evento.length > 0 ?(
    //   eventos = user.filter((val)=>{
    //       if(searchTerm == ""){
    //           return val
    //       }else if( val.title.toLowerCase().includes(searchTerm.toLowerCase())){
    //           return val
    //       }
    //   }).map((val, eve) => (
    //     <tr key={eve._id}>
         
    //       <td >{val.eve.title}</td>
    //       <td >{eve.ore}</td>
    //       <td >{eve.start}</td>
    //       <td >{eve.end}</td>
    //       {/* <td>
    //         {formatDate(eve.from)} - {eve.to ? formatDate(eve.to) : 'Oggi'}
    //       </td>
    //       <td>
    //         <button
    //           onClick={""}
    //           className="btn btn-danger"
    //         >
    //         <i class="fas fa-trash-alt"></i>
    //         </button> 
    //       </td>*/}
    //     </tr>
    //   ));
    //   }
    return (

<Fragment>
      {loading ? (
        <Spinner />
        ) : (
        <Fragment>
          
        <h3>{user.name} {user.surname} {" "} {"-- " + user.email}</h3>
        {/* <select className="select-anno" name="anno" id="anno" onChange={(e) => {setAnno(e.target.value) 
          setSearchTerm(pippo)}}>
       <option value={undefined} >Anno</option>
  <option value="2021">2021</option>
  <option value="2022">2022</option>
  <option value="2023">2023</option>
  <option value="2024">2024</option>
  <option value="2025">2025</option>
  
</select>      
    
    <select className="select-mese" name="mesi" id="mesi" onChange={(e) => 
      {setMese(e.target.value)
        setSearchTerm(pippo)}
      }>
 <option value={null} >Mese</option>
  <option value="-01">Gennaio</option>
  <option value="-02">Febbraio</option>
  <option value="-03">Marzo</option>
  <option value="-04">Aprile</option>
  <option value="-05">Maggio</option>
  <option value="-06">Giugno</option>
  <option value="-07">Luglio</option>
  <option value="-08">Agosto</option>
  <option value="-09">Settembre</option>
  <option value="-10">Ottobre</option>
  <option value="-11">Novembre</option>
  <option value="-12">Dicembre</option>
</select>
 <button className="ricerca"
 onClick={""}>Cerca</button>    */}
<input  className="ricerca" placeholder="" onChange={(e)=>setSearchTerm(e.target.value)}/>

{/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="estrazione"
                    filename={filenome}
                    sheet={filenome}
                    buttonText="Estrai"/> */}
          <div className=''>
         
          <table id="estrazione" className="table-to-xls">
    <thead className="thead">
      <tr>
      <th >Inizio</th>
        <th >Titolo</th>
        <th>Tipologia</th>
        <th >Ore Lavorate</th>
        <th>Straordinari</th>
        <th>Ferie</th>
        <th>Permessi</th>
        <th>Mutua</th>
        <th>Note</th>
        
        
      </tr>
    </thead>
    {userInfo.evento.length > 0 ? (
              userInfo.evento.filter((val)=>{
                if (searchTerm == ""){
                  return val
                }else if (val.start.toLowerCase().includes(searchTerm.toLowerCase())){
                  return val
                }
              
              }).map((val , evento) => (
                <tbody>
                 <tr>    
                 <td>{val.start === undefined ? null :  val.start.substring(0,10)}</td>     
              <td>{val.title}</td>
              <td>{val.tipoevento}</td>
                <td>{val.ore}</td>
                <td>{val.orestraordinario}</td>
                <td>{val.oreferie}</td>
                <td>{val.orepermessi}</td>
                <td>{val.oremutua}</td>
                <td>{val.note}</td>
                
              
                </tr>   
                </tbody>    
              ))
            ) : (
              <h4>Nessun evento trovato...</h4>
            )}
            </table>            
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};





  

export default TabEventUser;