import React, { Fragment, useState, useEffect  } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Header from '../components/layout/Header';



const LandingScreen = ({ location, history }) => {
  const dispatch = useDispatch();
 
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const redirect = location.search ? location.search.split('=')[1] : '/dashboard'


  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])
  return (
    <div>
    <Header/>
    
    <section className='landing'>
      <div className='dark-overlay'>
        <div className='landing-inner'>
          <h1 className='x-large'>Gestionale Ipse</h1>
          <p className='lead'>
            Crea il tuo profilo e monitora la tua situazione giorno per giorno           
          </p>
          <div className='buttons'>
            <Link to='/register' className='btn btn-primary'>
              Registra
            </Link>
            <Link to='/login' className='btn btn-light'>
              Login
            </Link>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};



export default LandingScreen;
