import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_DETAILS_RESET, USER_DETAILS_SUCCESS, USER_UPDATE_PROFILE_REQUEST, USER_UPDATE_PROFILE_RESET, USER_UPDATE_REQUEST, USER_UPDATE_RESET } from '../../constants/userConstants'
import axios from 'axios'
import { getUserDetails, updateUserProfile, updateUser } from '../../actions/userActions'
import Header from '../layout/Header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import formatDate from './../../utils/formatDate';

const ProfileEdit = ({location, history}) => {
  /*const [username, setUsername] = useState(userInfo.username)
  const [email, setEmail] = useState(userInfo.email)
  const [isAdmin, setIsAdmin] = useState(userInfo.isAdmin)*/


  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [datbir, setDatbir] = useState('')
  const [genere, setGenere] = useState('')
  const [nazionalit, setNazionalit] = useState('')
  const [cellulare, setCellulare] = useState('')
  const [company, setCompany] = useState('')
  const [luogo, setLuogo] = useState('')
  const [skills, setSkills] = useState([""])
  
  
  const [status, setStatus] = useState('')
  const [bio, setBio] = useState('')
 
  const dispatch = useDispatch()

  

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const { success } = userUpdate

  const redirect = location.search ? location.search.split('=')[1] : '/dashboard'
  const redirect2 = location.search ? location.search.split('=')[1] : '/login'
  const patterns = {
    telephone: "[0-9]{8,10}",
    
  };
  console.log(userInfo)

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!userInfo || !userInfo.username || success) {
        // dispatch({ type: USER_DETAILS_RESET})
        dispatch({ type: USER_DETAILS_SUCCESS,
                   })
        dispatch(getUserDetails(userInfo._id))
        dispatch({ type: USER_UPDATE_RESET })
        // dispatch(getUserDetails(userInfo._id))
        // dispatch({ type: USER_UPDATE_PROFILE_RESET })
       // dispatch(getUserDetails(userInfo._id))
        
   
      } 
    }
  }, [dispatch, history,  user, success])
console.log(userInfo)

const submitHandler = (e) => {
  e.preventDefault()

  try {
    var  datanascita = selectedDate
    var datanascita2 = datanascita.toISOString()
    var datanascita3 = formatDate(datanascita2)
     
   } catch (error) {
     
   }
  const ciao = document.getElementById('ocrop').value ||  document.getElementById('ocrop').placeholder
  
  const ciaone = ciao.split(',')
  
  dispatch(getUserDetails(userInfo._id))
 dispatch(updateUserProfile({ id: userInfo._id, username:userInfo.username,email:userInfo.email, isAdmin: userInfo.isAdmin, name, surname, company, skills: ciaone, luogo,bio, status, datbir: datanascita3 || userInfo.datbir, nazionalit, cellulare, genere}))
 
 
      //window.location.reload(`/profile/${user.id}`)
      history.push(redirect)
}
const [selectedDate, setSelectedDate]= useState(null)
try {
  
var pippo5 = userInfo.datbir.substring(0,10);
  
} catch (error) {
  
}

  return (
    <Fragment>
      <Header />
      <h1 className="large text-primary">Modifica Profilo</h1> 
      <p className="lead">
        <i className="fas fa-user" /> Aggiungi informazioni
      </p>
      <small>* = campi richiesti</small>
      <form className="form" onSubmit={submitHandler}>
      {/* <div className="form-group">
          <input
            type="text"
            placeholder={userInfo.name}
            name="nome"
            value={name } 
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={userInfo.surname}
            name="surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </div> */}
        
        <div className="form-group">
        <label>
            <h4>Cellulare</h4>

            </label>
          <input
            type="text"
            placeholder={userInfo.cellulare ?? "Inserisci cellulare" } 
            name="cellulare"
            value={cellulare || userInfo.cellulare}
            onChange={(e) => setCellulare(e.target.value)}
            maxLength={12}
            pattern={patterns.telephone}
            
          />
           <div className="form-group">
        <label>
            <h4>Genere</h4>

            </label>
          <select name="status" value={genere || userInfo.genere} onChange={(e) => setGenere(e.target.value)}>
            <option>* Seleziona Genere</option>
            <option value="Maschile">Maschile</option>
            <option value="Femminile">Femminile</option>
            <option value="Non Specificato">Non Specificato</option>
        
          </select>
         
        </div>
         
        </div>

        <div className="form-group">
        <label>
            <h4>Data Di Nascita</h4>

            </label>
            <DatePicker
            //  placeholderText  ={pippo5 || "Inserisci Data"   }
              selected={selectedDate  }
               value={selectedDate || pippo5 }
              onChange={date => setSelectedDate(date)}
              dateFormat='dd/MM/yyyy'
              isClearable
              
              showYearDropdown
              showMonthDropdown
              scrollableMonthYearDropdown
              scrollableYearDropdown
              id= "pippo5"
              
            />
            
         
         
        </div>
        
        <div className="form-group">
        <label>
            <h4>Nazionalità</h4>

            </label>
          <select name="status" value={nazionalit || userInfo.nazionalit} onChange={(e) => setNazionalit(e.target.value)}>
          <option value="">-- Seleziona Nazionalità --</option>
  <option value="afghan">Afghan</option>
  <option value="albanian">Albanian</option>
  <option value="algerian">Algerian</option>
  <option value="american">American</option>
  <option value="andorran">Andorran</option>
  <option value="angolan">Angolan</option>
  <option value="antiguans">Antiguans</option>
  <option value="argentinean">Argentinean</option>
  <option value="armenian">Armenian</option>
  <option value="australian">Australian</option>
  <option value="austrian">Austrian</option>
  <option value="azerbaijani">Azerbaijani</option>
  <option value="bahamian">Bahamian</option>
  <option value="bahraini">Bahraini</option>
  <option value="bangladeshi">Bangladeshi</option>
  <option value="barbadian">Barbadian</option>
  <option value="barbudans">Barbudans</option>
  <option value="batswana">Batswana</option>
  <option value="belarusian">Belarusian</option>
  <option value="belgian">Belgian</option>
  <option value="belizean">Belizean</option>
  <option value="beninese">Beninese</option>
  <option value="bhutanese">Bhutanese</option>
  <option value="bolivian">Bolivian</option>
  <option value="bosnian">Bosnian</option>
  <option value="brazilian">Brazilian</option>
  <option value="british">British</option>
  <option value="bruneian">Bruneian</option>
  <option value="bulgarian">Bulgarian</option>
  <option value="burkinabe">Burkinabe</option>
  <option value="burmese">Burmese</option>
  <option value="burundian">Burundian</option>
  <option value="cambodian">Cambodian</option>
  <option value="cameroonian">Cameroonian</option>
  <option value="canadian">Canadian</option>
  <option value="cape verdean">Cape Verdean</option>
  <option value="central african">Central African</option>
  <option value="chadian">Chadian</option>
  <option value="chilean">Chilean</option>
  <option value="chinese">Chinese</option>
  <option value="colombian">Colombian</option>
  <option value="comoran">Comoran</option>
  <option value="congolese">Congolese</option>
  <option value="costa rican">Costa Rican</option>
  <option value="croatian">Croatian</option>
  <option value="cuban">Cuban</option>
  <option value="cypriot">Cypriot</option>
  <option value="czech">Czech</option>
  <option value="danish">Danish</option>
  <option value="djibouti">Djibouti</option>
  <option value="dominican">Dominican</option>
  <option value="dutch">Dutch</option>
  <option value="east timorese">East Timorese</option>
  <option value="ecuadorean">Ecuadorean</option>
  <option value="egyptian">Egyptian</option>
  <option value="emirian">Emirian</option>
  <option value="equatorial guinean">Equatorial Guinean</option>
  <option value="eritrean">Eritrean</option>
  <option value="estonian">Estonian</option>
  <option value="ethiopian">Ethiopian</option>
  <option value="fijian">Fijian</option>
  <option value="filipino">Filipino</option>
  <option value="finnish">Finnish</option>
  <option value="french">French</option>
  <option value="gabonese">Gabonese</option>
  <option value="gambian">Gambian</option>
  <option value="georgian">Georgian</option>
  <option value="german">German</option>
  <option value="ghanaian">Ghanaian</option>
  <option value="greek">Greek</option>
  <option value="grenadian">Grenadian</option>
  <option value="guatemalan">Guatemalan</option>
  <option value="guinea-bissauan">Guinea-Bissauan</option>
  <option value="guinean">Guinean</option>
  <option value="guyanese">Guyanese</option>
  <option value="haitian">Haitian</option>
  <option value="herzegovinian">Herzegovinian</option>
  <option value="honduran">Honduran</option>
  <option value="hungarian">Hungarian</option>
  <option value="icelander">Icelander</option>
  <option value="indian">Indian</option>
  <option value="indonesian">Indonesian</option>
  <option value="iranian">Iranian</option>
  <option value="iraqi">Iraqi</option>
  <option value="irish">Irish</option>
  <option value="israeli">Israeli</option>
  <option value="italiana">Italiana</option>
  <option value="ivorian">Ivorian</option>
  <option value="jamaican">Jamaican</option>
  <option value="japanese">Japanese</option>
  <option value="jordanian">Jordanian</option>
  <option value="kazakhstani">Kazakhstani</option>
  <option value="kenyan">Kenyan</option>
  <option value="kittian and nevisian">Kittian and Nevisian</option>
  <option value="kuwaiti">Kuwaiti</option>
  <option value="kyrgyz">Kyrgyz</option>
  <option value="laotian">Laotian</option>
  <option value="latvian">Latvian</option>
  <option value="lebanese">Lebanese</option>
  <option value="liberian">Liberian</option>
  <option value="libyan">Libyan</option>
  <option value="liechtensteiner">Liechtensteiner</option>
  <option value="lithuanian">Lithuanian</option>
  <option value="luxembourger">Luxembourger</option>
  <option value="macedonian">Macedonian</option>
  <option value="malagasy">Malagasy</option>
  <option value="malawian">Malawian</option>
  <option value="malaysian">Malaysian</option>
  <option value="maldivan">Maldivan</option>
  <option value="malian">Malian</option>
  <option value="maltese">Maltese</option>
  <option value="marshallese">Marshallese</option>
  <option value="mauritanian">Mauritanian</option>
  <option value="mauritian">Mauritian</option>
  <option value="mexican">Mexican</option>
  <option value="micronesian">Micronesian</option>
  <option value="moldovan">Moldovan</option>
  <option value="monacan">Monacan</option>
  <option value="mongolian">Mongolian</option>
  <option value="moroccan">Moroccan</option>
  <option value="mosotho">Mosotho</option>
  <option value="motswana">Motswana</option>
  <option value="mozambican">Mozambican</option>
  <option value="namibian">Namibian</option>
  <option value="nauruan">Nauruan</option>
  <option value="nepalese">Nepalese</option>
  <option value="new zealander">New Zealander</option>
  <option value="ni-vanuatu">Ni-Vanuatu</option>
  <option value="nicaraguan">Nicaraguan</option>
  <option value="nigerien">Nigerien</option>
  <option value="north korean">North Korean</option>
  <option value="northern irish">Northern Irish</option>
  <option value="norwegian">Norwegian</option>
  <option value="omani">Omani</option>
  <option value="pakistani">Pakistani</option>
  <option value="palauan">Palauan</option>
  <option value="panamanian">Panamanian</option>
  <option value="papua new guinean">Papua New Guinean</option>
  <option value="paraguayan">Paraguayan</option>
  <option value="peruvian">Peruvian</option>
  <option value="polish">Polish</option>
  <option value="portuguese">Portuguese</option>
  <option value="qatari">Qatari</option>
  <option value="romanian">Romanian</option>
  <option value="russian">Russian</option>
  <option value="rwandan">Rwandan</option>
  <option value="saint lucian">Saint Lucian</option>
  <option value="salvadoran">Salvadoran</option>
  <option value="samoan">Samoan</option>
  <option value="san marinese">San Marinese</option>
  <option value="sao tomean">Sao Tomean</option>
  <option value="saudi">Saudi</option>
  <option value="scottish">Scottish</option>
  <option value="senegalese">Senegalese</option>
  <option value="serbian">Serbian</option>
  <option value="seychellois">Seychellois</option>
  <option value="sierra leonean">Sierra Leonean</option>
  <option value="singaporean">Singaporean</option>
  <option value="slovakian">Slovakian</option>
  <option value="slovenian">Slovenian</option>
  <option value="solomon islander">Solomon Islander</option>
  <option value="somali">Somali</option>
  <option value="south african">South African</option>
  <option value="south korean">South Korean</option>
  <option value="spanish">Spanish</option>
  <option value="sri lankan">Sri Lankan</option>
  <option value="sudanese">Sudanese</option>
  <option value="surinamer">Surinamer</option>
  <option value="swazi">Swazi</option>
  <option value="swedish">Swedish</option>
  <option value="swiss">Swiss</option>
  <option value="syrian">Syrian</option>
  <option value="taiwanese">Taiwanese</option>
  <option value="tajik">Tajik</option>
  <option value="tanzanian">Tanzanian</option>
  <option value="thai">Thai</option>
  <option value="togolese">Togolese</option>
  <option value="tongan">Tongan</option>
  <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
  <option value="tunisian">Tunisian</option>
  <option value="turkish">Turkish</option>
  <option value="tuvaluan">Tuvaluan</option>
  <option value="ugandan">Ugandan</option>
  <option value="ukrainian">Ukrainian</option>
  <option value="uruguayan">Uruguayan</option>
  <option value="uzbekistani">Uzbekistani</option>
  <option value="venezuelan">Venezuelan</option>
  <option value="vietnamese">Vietnamese</option>
  <option value="welsh">Welsh</option>
  <option value="yemenite">Yemenite</option>
  <option value="zambian">Zambian</option>
  <option value="zimbabwean">Zimbabwean</option>
          </select>
          
        </div>
        <div className="form-group">
        <label>
            <h4>Stato Professionale</h4>

            </label>
          <select name="status" value={status || userInfo.status} onChange={(e) => setStatus(e.target.value)}>
            <option>* Seleziona stato Professionale</option>
            <option value="Developer">Developer</option>
            <option value="Junior Developer">Junior Developer</option>
            <option value="Senior Developer">Senior Developer</option>
            <option value="Manager">Manager</option>
            <option value="Student or Learning">Studente o Apprendista</option>
            <option value="Instructor">Instruttore o Insegnante</option>
            
            <option value="Other">Altro</option>
          </select>
          <small className="form-text">
            Dacci un idea del tuo attuale inquadramento
          </small>
        </div>
        <div className="form-group">
        <label>
            <h4>Azienda</h4>

            </label>
          <input
            type="text"
            placeholder={userInfo.company ?? "Azienda"}
            name="company"
            value={company || userInfo.company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <small className="form-text">
            Azienda 
          </small>
        </div>
       
        <div className="form-group">
        <label>
            <h4>Luogo</h4>

            </label>
          <input
            type="text"
            placeholder={userInfo.luogo}
            name="location"
            value={luogo || userInfo.luogo}
            onChange={(e) => setLuogo(e.target.value)}
          />
          <small className="form-text">
            Citta & provinacia (es. Milano, MI)
          </small>
        </div>
        <div className="form-group">
        <label>
            <h4>Skills</h4>

            </label>
          <input
            type="text"
            placeholder={userInfo.skills}
            name="skills"
            value={skills || userInfo.skills}
            onChange={(e) => setSkills(e.target.value)}
            id="ocrop"
          />
          <small className="form-text">
            Per favore usa la virgola per separare i valori (es. HTML,CSS,JavaScript,PHP)
          </small>
        </div>
       
        <div className="form-group">
        <label>
            <h4>Descrizione Biografica</h4>

            </label>
          <textarea
            placeholder={userInfo.bio ?? "Descrizione "}
            name="bio"
            value={bio || userInfo.bio}
            onChange={(e) => setBio(e.target.value)}
          />
          <small className="form-text">Parlaci un pò di te...</small>
        </div>
       

        <input type="submit" className="btn btn-primary my-1" />
        
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
        
      </form>
    </Fragment>
  );
};


export default  ProfileEdit;
