import React, { Fragment, useEffect,  useState  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getUserByid, addUserDocumentiAdmin, deleteUserDocumentiAdmin } from '../../../actions/userActions'
import formatDate from '../../../utils/formatDate';
import Spinner from '../../layout/Spinner';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Modal from 'react-modal';
// import AddDocumentiAdmin from './AddDocumentiAdmin';
import axios from'axios';




const TabDocumenti = ({dipendente,history, location  }) => {
 const userId = dipendente
 const dispatch = useDispatch()
 const userByid = useSelector((state) => state.userByid)
const { loading, error, user } = userByid
const [searchTerm, setSearchTerm]= useState('')
const [anno, setAnno] = useState('')
const [mese, setMese] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false) 
  const pippo = anno +  mese
    useEffect(() => {
        if (!user ?? user.isAdmin ) {
          window.location.push('/login')
        } 
        else {
          
          if (!user || !user.name ) {
            
            dispatch(getUserByid(dipendente))
            // console.log(user)
            // //history.push(`/profile/${userId}`)
            // console.log('****')
            // console.log(user)
    
            dispatch(getUserByid(dipendente))
    
    
            
           
      
          } 
        }
      }, [dispatch, user,  /*userInfo*/ ])

  

  
    

      const documenti = user.documenti
      const filenome = user.name + Date.now();

      const downDoc= (name) => {
        const config = {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
          },
        }
    fetch(`https://api.gestionale.ipsedocet.com/bucketCur?key=documenti/`+name, config)
    .then((response)=>{
    
      console.log(response.body)
     
        response.json().then(res=>{
    
          console.log(res.result.data)
          var myBlob = new Blob ([new Uint8Array(res.result.data)]);
          let url = window.URL.createObjectURL(myBlob);
          let a = document.createElement('a');
        console.log("cia")
    
          a.href = url;
          a.download = name;
          a.click()
    
        }
    
          )
      
      
    })
    }

    const deleteDoc= (name) => {
      console.log(name)
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
        },
      }
    axios.delete (`https://api.gestionale.ipsedocet.com/bucket?key=documenti/`+name, config)
  .then((response)=>{
  
    console.log(response.body)
   
      // response.json().then(res=>{
        
      //  console.log(res)
      // //   var myBlob = new Blob ([new Uint8Array(res.result.data)]);
      // //   let url = window.URL.createObjectURL(myBlob);
      // //   let a = document.createElement('a');
      // // console.log("cia")
  
      // //   a.href = url;
      // //   a.download = name;
      // //   a.click()
  
      // }
  
        // )
    
    
  })
  }

    const deleteDocumenti= (userId,_id) => {

      if (window.confirm('Sei sicuro?')) {
        dispatch(getUserByid(userId))
          dispatch(deleteUserDocumentiAdmin(userId,_id,))
          
          dispatch(getUserByid(userId))
         //dispatch(getUserDetails(userInfo._id))
          //dispatch({type:USER_UPDATE_PROFILE_SUCCESS})
          //dispatch({type: USER_UPDATE_PROFILE_RESET })
     
      }
      }
    //   const submitHandler = (e) =>{
 
        
    // let a = document.getElementById('anno').value
    // let b = document.getElementById('mese').value

    // const pippo = a + "-" + b +"-"
           
    //       }
   
    
     
    //     {users.evento.length > 0 ?(
    //   eventos = user.filter((val)=>{
    //       if(searchTerm == ""){
    //           return val
    //       }else if( val.title.toLowerCase().includes(searchTerm.toLowerCase())){
    //           return val
    //       }
    //   }).map((val, eve) => (
    //     <tr key={eve._id}>
         
    //       <td >{val.eve.title}</td>
    //       <td >{eve.ore}</td>
    //       <td >{eve.start}</td>
    //       <td >{eve.end}</td>
    //       {/* <td>
    //         {formatDate(eve.from)} - {eve.to ? formatDate(eve.to) : 'Oggi'}
    //       </td>
    //       <td>
    //         <button
    //           onClick={""}
    //           className="btn btn-danger"
    //         >
    //         <i class="fas fa-trash-alt"></i>
    //         </button> 
    //       </td>*/}
    //     </tr>
    //   ));
    //   }
    return (
<Fragment>      

     

      {loading ? (
        <Spinner />
        ) : (
        <Fragment>
        
        {/* <select className="select-anno" name="anno" id="anno" onChange={(e) => {setAnno(e.target.value) 
          setSearchTerm(pippo)}}>
       <option value={undefined} >Anno</option>
  <option value="2021">2021</option>
  <option value="2022">2022</option>
  <option value="2023">2023</option>
  <option value="2024">2024</option>
  <option value="2025">2025</option>
  
</select>      
    
    <select className="select-mese" name="mesi" id="mesi" onChange={(e) => 
      {setMese(e.target.value)
        setSearchTerm(pippo)}
      }>
 <option value={null} >Mese</option>
  <option value="-01">Gennaio</option>
  <option value="-02">Febbraio</option>
  <option value="-03">Marzo</option>
  <option value="-04">Aprile</option>
  <option value="-05">Maggio</option>
  <option value="-06">Giugno</option>
  <option value="-07">Luglio</option>
  <option value="-08">Agosto</option>
  <option value="-09">Settembre</option>
  <option value="-10">Ottobre</option>
  <option value="-11">Novembre</option>
  <option value="-12">Dicembre</option>
</select>
 <button className="ricerca"
 onClick={""}>Cerca</button>    */}


          <div className=''>
         
          <table id="estrazionePagamentiSingolo" className="table-to-xls">
    <thead className="thead">
      <tr >
        <th className="th1Add" >Titolo</th>
       
        <th> Action </th>
        
      </tr>
    </thead>
    {user.documenti.length > 0 ? (
              user.documenti.filter((val)=>{
                if (searchTerm == ""){
                  return val
                }else if (val.from.toLowerCase().includes(searchTerm.toLowerCase())){
                  return val
                }
              
              }).map((val , pagamenti) => (
                <tbody>
                 <tr>    
                     
              <td className="th1Add">{val.title}</td>
             
              <td>
        <button  className="btn btn-success"
          onClick={() => downDoc(val.filecaricato)}
          
        ><i class="fas fa-download"></i></button>
        
        <button  className="btn btn-danger"
          onClick={() => {
            deleteDocumenti(userId, val._id); 

            deleteDoc(val.filecaricato)}}
          
        ><i class="fas fa-trash"></i></button>
        
        </td>
                </tr>   
                </tbody>    
              ))
            ) : (
              <h4>Nessun documento trovato...</h4>
            )}
            </table>            
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};





  

export default TabDocumenti;