import React, { Fragment, useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET ,USER_LOGIN_SUCCESS, EVENTO_DETAILS_REQUEST, EVENTO_DETAILS_SUCCESS, USER_DETAILS_RESET, USER_UPDATE_SUCCESS, USER_UPDATE_REQUEST, USER_UPDATE_RESET, EVENTO_DETAILS_RESET, USER_UPDATE_PROFILE_SUCCESS, EVENTO_DETAILS_USER_SUCCESS} from '../../../constants/userConstants'
import { editUserEvento,getUserEvento,getUserDetails } from '../../../actions/userActions';
import './windstyleAdmin.css';
// import Evento from '../../dashboard/Evento';
import formatDate from '../../../utils/formatDate';

export const EditEvento =  ({ match,location, /*eventodata,giorno, idUser,*/ idEvento, history, /*titolo, nore, gdate, starteve, endeve, norepermessi, noreferie, noremutua, norestraordinario, gtipoevento, gnote*/ }) => {
  //const userId = match.params.id
  // alert(idEvento)
const IDEVENTO = idEvento
// console.log(pippo)
  
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userGetEvento = useSelector((state)=> state.userGetEvento)
  const {eventodata}= userGetEvento
  console.log(eventodata)
  // idEvento=localStorage.getItem("idEvento")
  

  const [title, setTitle] = useState('')
 
  const [ore, setOre] = useState('')
//  const [start, setStart] = useState('')
///  const [end, setEnd] = useState('')
 // const [date, setDate] = useState('')
  //const [giorno, setGiorno] = useState('')
  const [orafine, setOrafine] = useState('')
  const [orainizio, setOrainizio] = useState('')

  const [tipoevento, setTipoevento] = useState('')
  const [orepermessi, setOrepermessi] = useState('') 
  const [orestraordinario, setOrestraordinario] = useState('')
  const [oreferie, setOreferie] = useState('')
  const [oremutua, setOremutua] = useState('')
  const [note, setNote] = useState('')
  const [all, setAll] = useState(false)
  

 
  const giorno= eventodata.date
  // alert(giorno)




  // const userEditEvento = useSelector((state) => state.userEditEvento)
  // const { success } = userEditEvento
 
  //const redirect = location.search ? location.search.split('=')[1] : `/profile/${user._id}`




  useEffect(() => {
    if (!userInfo) {
     history.push('/login')
    } else {
      if (!userInfo || !userInfo.username  ) {
      
    // dispatch(getUserEvento(idEvento))
    //  console.log(eventodata)

       
        window.location.reload( `/dashboard`);

        //history.push('/dashboard')
      } 
    }
  }, [dispatch, history, userInfo])

  useEffect(() => {
    dispatch(getUserEvento(IDEVENTO))
    dispatch({type:EVENTO_DETAILS_USER_SUCCESS,
    payload:eventodata})
    console.log(eventodata)
    setTimeout(() => {
      // dispatch(getUserEvento(idEvento))
      // console.log(eventodata)
  
      setTipoevento("Lavorato")
    setTipoevento("Ferie")
    setTipoevento("")
    funzioneSelectOption("")
    
    },[eventodata], 1);
   
  
    
  }, [])

  const funzioneSelectOption=(e)=>{
    // let oreLAVORATE = document.getElementById("orelavorate")
    let status = document.getElementById("tipoevento")
  //  alert(tipoevento.value)
     
   
    // if (pippo === undefined){
    //  status.value= "Lavorato"
     
    // } 
    
    if (status.value === "Lavorato" ){
      document.getElementById("orelavorate").style.display="block"
      
    } 
    else {
      document.getElementById("orelavorate").style.display="none"
    }
    if(status.value === "Straordinario"){
    
      document.getElementById("orestraordinario").style.display="block"
    }else{
      document.getElementById("orestraordinario").style.display="none"
    }
  
     if(status.value === "Mutua"){
     document.getElementById("oremutua").style.display="block"
    }else{
      document.getElementById("oremutua").style.display="none"
    }
     if (status.value === "Ferie"){
    
      document.getElementById("oreferie").style.display="block"
    }else{
      document.getElementById("oreferie").style.display="none"
    }
    if (status.value === "Permesso"){
    
      document.getElementById("orepermesso").style.display="block"
    }else{
      document.getElementById("orepermesso").style.display="none"
    }
   
    // setTipoevento(status.value)
  }

const onChangeC = e =>{
//  let giorno = starteve.slice(0, 10)
//  console.log(giorno + "######")
  let a= document.getElementById("init").value="09:00"; 
  let b= document.getElementById("fin").value="18:00";
 
    const  start= giorno+'T'+ a;
    const  end= giorno+'T'+ b;
     
    }

const submitHandler = (e) => {
  // const giorno = starteve.slice(0, 10)
  // console.log(giorno + "######")
const start= giorno+'T'+  document.getElementById("init").value+':00';
const end= giorno+'T'+  document.getElementById("fin").value+':00';
const date= giorno;

  e.preventDefault()
  // dispatch(getUserDetails(idUser))
    dispatch(editUserEvento( idEvento,{ title, ore, start, end,  date, orepermessi, oreferie, oremutua, orestraordinario, tipoevento, note}))
   // dispatch(USER_UPDATE_PROFILE_SUCCESS)
  
    // dispatch(EVENTO_DETAILS_USER_RESET)
  //  dispatch(USER_UPDATE_RESET)
    // dispatch(getUserDetails(userInfo._id))
  //  dispatch(USER_UPDATE_SUCCESS)
    // history.push(redirect)
    


}

    
  return (

    
    <div className="ModFinestra">
    <div className='bodyWind'>
    <Fragment>
    
     
   
 
     
      <form
        className="form"
        onSubmit={submitHandler}
      >
        
        <div className="inputWind">
          <input
            type="text"
            placeholder={eventodata.title}
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="inputWind"
            
            
          />
        </div>

        <div className="inputWind" onChange={funzioneSelectOption} >
          <select id="tipoevento" name="tipoevento" value={tipoevento } onChange={(e) => setTipoevento(e.target.value)} required>
             <option value="">* Seleziona attività</option> 
            <option   value="Lavorato">Lavorato</option>
            <option value="Ferie">Ferie</option>
            <option value="Straordinario">Straordinario</option>
            <option value="Mutua">Mutua</option>
            <option value="Permesso">Permesso</option>           
          </select> 
        </div>  

        <div id="orelavorate" className="inputWind">
          <input 
            type="number"
            placeholder= {ore || eventodata.ore || 0}
            name="ore"
            value={ore  }
            onChange={(e) => setOre(e.target.value)}
            className="inputWind"
            max="24"
            id="orelavorate"
            
          />
        </div>

        <div id="oreferie" className="inputWind">
          <input
            type="number"
            placeholder= { oreferie|| eventodata.oreferie || "0"}
            name="oreferie"
            value={oreferie }
            onChange={(e) => setOreferie(e.target.value)}
            className="inputWind"
            max="24"
            
          />
        </div>

        <div id="orepermesso" className="inputWind">
          <input
            type="number"
            placeholder={orepermessi || eventodata.orepermessi || "0"}
            name="orepermessi"
            value={orepermessi }
            onChange={(e) => setOrepermessi(e.target.value)}
            className="inputWind"
            max="24"
            
          />
        </div>

        <div id="orestraordinario" className="inputWind">
          <input
            type="number"
            placeholder={orestraordinario|| eventodata.orestraordinario|| "0" }
            name="orestraordinario"
            value={orestraordinario }
            onChange={(e) => setOrestraordinario(e.target.value)}
            className="inputWind"
            max="24"
            
          />
        </div>

        <div id="oremutua" className="inputWind">
          <input
            type="number"
            placeholder={oremutua || eventodata.oremutua || "0"}
            name="orestraordinario"
            value={oremutua }
            onChange={(e) => setOremutua(e.target.value)}
            className="inputWind"
            max="24"
            
          />
        </div>

        <div className="form-group">
          <textarea
            placeholder= {eventodata.note || "Note"}
            name="bio"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            maxLength={0,25}
          />
          
        </div>  
        
        {/* <div className="inputWind">

          <input
            type="date"
            
            name="date"
            value={giorno}
            onChange={giorno}
            className="inputWind"           
          />
        </div> */}
        <div>
        <p className="textinputcheck" >
        <input
          type="checkbox"
          name="all"
         
          onChange={onChangeC}
          
        />{' '}
        Giornata Intera
      </p>
        <div className="contWind1">
        <h4 className="subtitleWind">Inizio</h4>
          <input
            type="time"
            name="orainizio"
            value={  orainizio || "09:00"  }
            onChange={(e) => setOrainizio(e.target.value)}
            className="inputWind"
            disabled={all}
            id="init"
          />
        </div>
        <div className="contWind2">
        <h4 className="subtitleWind">Fine</h4>
          <input
            type="time"
            name="orafine"
            value={ orafine || "18:00"   }
            onChange={(e) => setOrafine(e.target.value)}
            className="inputWind"
            disabled={all}
            id="fin"
          />
        </div>
       </div>
       
       
       <div className="btnDiv">
        <input 
        
        type="submit" className="btnSalva" />
        
        
        </div>
      </form>
    </Fragment>
    </div>
    </div>
  );
};



export default EditEvento;
