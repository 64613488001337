        export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
        export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
        export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
        export const USER_LOGOUT = 'USER_LOGOUT'

        export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
        export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
        export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'


        export const LINGUE_DELETE_REQUEST = 'EXPERIENCE_DELETE_REQUEST'
        export const LINGUE_DELETE_SUCCESS = 'EXPERIENCE_DELETE_SUCCESS'
        export const LINGUE_DELETE_FAIL = 'EXPERIENCE_DELETE_FAIL'

        export const EXPERIENCE_DELETE_REQUEST = 'EXPERIENCE_DELETE_REQUEST'
        export const EXPERIENCE_DELETE_SUCCESS = 'EXPERIENCE_DELETE_SUCCESS'
        export const EXPERIENCE_DELETE_FAIL = 'EXPERIENCE_DELETE_FAIL'

        export const EXPERIENCE_DELETE_ADMIN_REQUEST = 'EXPERIENCE_DELETE_ADMIN_REQUEST'
        export const EXPERIENCE_DELETE_ADMIN_SUCCESS = 'EXPERIENCE_DELETE_ADMIN_SUCCESS'
        export const EXPERIENCE_DELETE_ADMIN_FAIL = 'EXPERIENCE_DELETE_ADMIN_FAIL'

        export const EDUCATION_DELETE_REQUEST = 'EDUCATION_DELETE_REQUEST'
        export const EDUCATION_DELETE_SUCCESS = 'EDUCATION_DELETE_SUCCESS'
        export const EDUCATION_DELETE_FAIL = 'EDUCATION_DELETE_FAIL'

        export const EVENTO_DELETE_REQUEST = 'EVENTO_DELETE_REQUEST'
        export const EVENTO_DELETE_SUCCESS = 'EVENTO_DELETE_SUCCESS'
        export const EVENTO_DELETE_FAIL = 'EVENTO_DELETE_FAIL'

        export const EVENTO_DETAILS_USER_REQUEST = 'EVENTO_DETAILS_USER_REQUEST'
        export const EVENTO_DETAILS_USER_SUCCESS = 'EVENTO_DETAILS_USER_SUCCESS'
        export const EVENTO_DETAILS_USER_FAIL = 'EVENTO_DETAILS_USER_FAIL'
        export const EVENTO_DETAILS_USER_RESET = 'EVENTO_DETAILS_USER_RESET'

                export const EVENTO_DETAILS_REQUEST = 'EVENTO_DETAILS_REQUEST'
                export const EVENTO_DETAILS_SUCCESS = 'EVENTO_DETAILS_SUCCESS'
                export const EVENTO_DETAILS_FAIL = 'EVENTO_DETAILS_FAIL'
                export const EVENTO_DETAILS_RESET = 'EVENTO_DETAILS_RESET'

        export const PAYMENT_DELETE_REQUEST = 'PAYMENT_DELETE_REQUEST'
        export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS'
        export const PAYMENT_DELETE_FAIL = 'PAYMENT_DELETE_FAIL'

        export const DOCUMENTI_DELETE_REQUEST = 'DOCUMENTI_DELETE_REQUEST'
        export const DOCUMENTI_DELETE_SUCCESS = 'DOCUMENTI_DELETE_SUCCESS'
        export const DOCUMENTI_DELETE_FAIL = 'DOCUMENTI_DELETE_FAIL'


        export const PROJECT_DELETE_REQUEST = 'USER_UPDATE_REQUEST'
        export const PROJECT_DELETE_SUCCESS = 'USER_UPDATE_SUCCESS'
        export const PROJECT_DELETE_FAIL = 'USER_UPDATE_FAIL'


        export const USER_BYID_REQUEST = 'USER_BYID_REQUEST'
        export const USER_BYID_SUCCESS = 'USER_BYID_SUCCESS'
        export const USER_BYID_FAIL = 'USER_BYID_FAIL'
        export const USER_BYID_RESET = 'USER_BYID_FAIL'



  
        

        