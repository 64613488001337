import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import '../../App.css'
import { USER_DETAILS_RESET } from '../../constants/userConstants';
const Sidebar=({curriculum, dipendente})=> {
  const dispatch = useDispatch()
  const resetEdit = () => {
    
    window.location=('/edit-profile')
    dispatch({
      type: USER_DETAILS_RESET,
    })
  }
  const resetEditCV = () => {
    
    window.location=('/edit-curriculum')
    dispatch({
      type: USER_DETAILS_RESET,
    })
  }

  const resetListaEve = () => {
    
    window.location=('/list-eventi/')
    dispatch({
      type: USER_DETAILS_RESET,
    })
  }
    return (
        <div >
        <div className="sidebar">
        {/* <div className="divSidebarName">
          <h4 className="divSidebarNameDipendente">Benvenuto {dipendente}</h4>
        </div> */}
        
        <ul >
        <div className="divsid" >
        <li >
        <Link className="sidebarlist"  to={``} >
        <i className='fas fa-user-circle ' /> <t className="hide-sm">Profilo</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   onClick={()=> resetEdit()} >
          <i className='fas fa-cogs' /> <t className="hide-sm">Edit </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  onClick={()=> resetEditCV()} >
        <i class="fas fa-id-badge"></i> <t className="hide-sm">Edit CV </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to='/add-experience/'>
          <i className='fab fa-black-tie ' /> <t className="hide-sm">Lavoro </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to='/add-education' >
          <i className='fas fa-graduation-cap ' /> <t className="hide-sm">Studi </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to='/add-project' >
          <i className='fas fa-briefcase ' />  <t className="hide-sm">Progetto </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/curriculum/`}>
        <i class="far fa-file"/> <t className="hide-sm">Curriculum </t>
        </Link>
        </li>
        </div> 
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/add-lingue`}>
        <i class="fas fa-language"></i>  <t className="hide-sm">Lingue </t>
        </Link>
        </li>
        </div> 
        {/* <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  onClick={()=> resetListaEve()}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Lista Eventi </t>
        </Link>
        </li>
        </div> */}
       
        {/* <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to='/add-project' >
        <i class="fas fa-scroll"/> <t className="hide-sm">Progetto </t>
        </Link>
        </li>
        </div> */}
        {/* <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to='/add-project' >
        <i class="fas fa-scroll"/> <t className="hide-sm">Curriculum </t>
        </Link>
        </li>
        </div> */}
        </ul>
        </div>
        </div>
          
          
        
    )
}

export default Sidebar

