import React, { Fragment, useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET ,USER_LOGIN_SUCCESS} from '../../../constants/userConstants'
import { getUserByid , updateBlocco } from '../../../actions/userActions';
import './windstyleAdmin.css';
export const AddBlocco =  ({ match,location, history, giorno }) => {
  //const userId = match.params.id
  const [blocco, setBlocco] = useState('')
 
 
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid


  const userUpdateBlocco = useSelector((state) => state.userUpdateBlocco)
  const { success } = userUpdateBlocco
  

  //const redirect = location.search ? location.search.split('=')[1] : `/profile/${user._id}`
const userId= user._id;

  useEffect(() => {
    if (!user) {
     history.push('/login')
    } else {
      if (!user || !user.username || success) {
       
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserByid(user._id))
       //dispatch({type:USER_LOGIN_SUCCESS})

        
        window.location.reload( `/profile/${user._id}`);

        //history.push('/dashboard')
      } 
    }
  }, [dispatch, history, userInfo,  success])




const submitHandler = (e) => {


  e.preventDefault()
  dispatch(getUserByid(user._id))
    dispatch(updateBlocco(user._id,{ id: user._id, username:user.username,email:user.email, isAdmin: userInfo.isAdmin, blocco}))
    dispatch(getUserByid(user._id))
   
    //history.push(redirect)
    


}

    
  return (

    
    <div className="ModFinestra">
    <div className='bodyWind'>
    <Fragment>
    
      <p className="titleWind2">Blocca Eventi </p>
     
      <p className="testoWind">
        <i className="fas fa-code-branch" /> {user.name }{" "}{user.surname}
       
      </p>
 
     
      <form
        className="form"
        onSubmit={submitHandler}
      >
        
       
        <div className="inputWind2">
          <input
            type="date"
            placeholder=""
            name="ore"
            value={blocco}
            onChange={(e) => setBlocco(e.target.value)}
            className="inputWind"
            required
          />
        </div>
      
       <div className="btnDiv">
        <input type="submit" className="btnSalva2" />
        
        
        </div>
      </form>
    </Fragment>
    </div>
    </div>
  );
};



export default AddBlocco;
