import React, { Fragment, useEffect,  useState  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import ProfileExperience from './ProfileExperience';
import ProfileEducation from './ProfileEducation';
import ProfileProject from './ProfileProject';
import ProfilePayment from './ProfilePayment';
import {getUserDetails,deleteUser, getUserByid ,deleteUserExperienceAdmin, deleteUserEducationAdmin, deleteUserProjectAdmin, deleteUserPaymentAdmin} from '../../actions/userActions'
import formatDate from '../../utils/formatDate';
import Evento from '../dashboard/Evento';
import Sidebar from '../dashboard/Sidebar';
import SidebarAdmin from '../Admin/SidebarAdmin';
import Header from '../layout/Header';
import EventoAdmin from '../Admin/sidebarAdmin/EventoAdmin';
import TabPagamenti from '../Admin/sidebarAdmin/TabPagamenti';
import TabCurriculum from '../Admin/sidebarAdmin/TabCurriculum';
import { listUsers, login } from '../../actions/userActions'
import { USER_BYID_RESET } from '../../constants/userConstants';




const ListCurriculum = ({match,  history, location }) => {
  
  
  const dispatch = useDispatch()
  
  const [searchTerm, setSearchTerm]= useState('')
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userList = useSelector((state) => state.userList)
  const { loading, error, users } = userList
 
  const deleteHandler = (_id) => {
  
    if (window.confirm('Sei sicuro?')) {
      dispatch(deleteUser(_id))
      
      history.push('/profiles')
    }
  }

  const pippo = () => {
    
    window.location=('/profiles')
    dispatch({
      type: USER_BYID_RESET,
    })
  }

  const downCur = (name) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
fetch(`https://api.gestionale.ipsedocet.com/bucketCur?key=curriculum/`+name, config)
.then((response)=>{

  console.log(response.body)
 
    response.json().then(res=>{

      console.log(res.result.data)
      var myBlob = new Blob ([new Uint8Array(res.result.data)]);
      let url = window.URL.createObjectURL(myBlob);
      let a = document.createElement('a');
    console.log("cia")

      a.href = url;
      a.download = name;
      a.click()

    }

      )
  
  
})
}
  

  useEffect(() => {
    if (userInfo ?? userInfo.isAdmin ) {
     
      dispatch(listUsers(users))
    } 
    else {
      
      history.push('/login')
    }
  }, [dispatch, history,userInfo,  /*userInfo*/ ])
  
  



  return (
    <Fragment>
    <Header />
    <div className="sidebar">
<ul >
<div className="divsid" >
<li >
<Link className="sidebarlist" to={``} >
<i className='fas fa-user-circle ' /> <t className="hide-sm">Profilo</t>
</Link>
</li>
</div>
<div className="divsid" >
<li>
<Link className="sidebarlist" onClick={()=>pippo()} >
<i class="fas fa-users"></i> <t className="hide-sm">Dipendenti </t>
</Link>
</li>
</div>
<div className="divsid" >
<li>
<Link className="sidebarlist" to="/list-curriculum" >
<i class="fas fa-users"></i> <t className="hide-sm">Lista CV </t>
</Link>
</li>
</div>
</ul>
</div>

          
    {loading ? (
        <Spinner />
        ) : (
        <Fragment>
        
       <div className="contdash">
<input  className="ricerca" placeholder="Nome - Cognome - Username" onChange={(e)=>setSearchTerm(e.target.value)}/>

{/* <ReactHTMLTableToExcel
                    id="table-xls-button"
                    className="download-table-xls-button"
                    table="estrazionePagamentiSingolo"
                    filename={filenome}
                    sheet={filenome}
                    buttonText="Estrai"/> */}
          <div className=''>
         
          <table id="estrazionePagamentiSingolo" className="table-to-xls">
    <thead className="thead">
      <tr >
        <th >Nome e Cognome</th>
        <th>Username</th>
        <th >Email</th>
        <th>Ruolo</th>
        
        
      </tr>
    </thead>
    {users.length > 0 ? (
              users.filter((val)=>{
                if (searchTerm == ""){
                  return val
                }else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())){
                  return val
                }
                else if (val.surname.toLowerCase().includes(searchTerm.toLowerCase())){
                  return val
                }
                else if (val.username.toLowerCase().includes(searchTerm.toLowerCase())){
                  return val
                }
              
              }).map((val , users) => (
                <tbody>
                 <tr >    
                     
              <td >{val.name}{" "}{val.surname}</td>
                <td >{val.username}</td>
                
                <td >{val.email}</td>
                <td>{val.situazione}</td>
                <td hidden={val.curriculum===undefined} > <button  className="btn btn-success"
          onClick={() => downCur(val.curriculum)}
          
        ><i class="fas fa-download"></i></button></td>
                </tr>   
                </tbody>    
              ))
            ) : (
              <h4>Nessun curriculum trovato...</h4>
            )}
            </table>            
          </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};




  

export default ListCurriculum;
