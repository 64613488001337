import React, { Fragment, useState, useEffect  } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../actions/userActions';
import Header from '../components/layout/Header';

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/dashboard'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }


  return (
    <Fragment>
      <Header />
      <h1 className="large text-primary">Log In</h1>
      <p className="lead">
        <i className="fas fa-user" /> Entra nel tuo Account
      </p>
      <form className="form" onSubmit={submitHandler}>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            minLength="6"
          />
        </div>
        <button type="submit" className="btn btn-primary" value="Login" > Login </button>
      </form>
      <p className="my-1">
        Non hai un Account? <Link to="/register">Registra</Link>
      </p>
    </Fragment>
  );
};



export default LoginScreen;
