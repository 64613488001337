import React , { useRef } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import { useReactToPrint } from "react-to-print";
import {getUserDetails,deleteUser, getUserByid ,deleteUserExperienceAdmin, deleteUserEducationAdmin, deleteUserProjectAdmin, deleteUserPaymentAdmin} from '../../../actions/userActions'

import Header from '../../layout/Header';
import ComponentToPrint from './ComponentToPrint';


const Print = ({match, history}) => {
  const userId = match.params.id
  const dispatch = useDispatch()
  console.log(userId)
    const componentRef = useRef();
   
    const deleteHandler = (_id) => {
  
      if (window.confirm('Sei sicuro?')) {
        dispatch(deleteUser(_id))
        
        history.push('/profiles')
      }
    } 

      console.log(componentRef.current)
    const handlePrint = useReactToPrint({
      
      content: () => componentRef.current,
    });
  
    return (
      <div>
        <Header/>
        <div >
        <div className="sidebar">
        
        
        <ul >
        <div className="divsid" >
        <li >
        <Link className="sidebarlist"  to={``} >
        <i className='fas fa-user-circle ' /> <t className="hide-sm">Profilo</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   to="/profiles" >
        <i class="fas fa-users"></i> <t className="hide-sm">Dipendenti </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"   to={`/edit-profile/${userId}`} >
        <i className='fas fa-cogs' /> <t className="hide-sm">Edit </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to={`/add-experience/${userId}`}>
          <i className='fab fa-black-tie ' /> <t className="hide-sm">Lavoro </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to={`/add-education/${userId}`} >
          <i className='fas fa-graduation-cap ' /> <t className="hide-sm">Studi </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/add-project/${userId}`}>
          <i className='fas fa-briefcase ' />  <t className="hide-sm">Progetto </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to= {`/add-payment/${userId}`}>
          <i className='fas fa-credit-card ' /> <t className="hide-sm">Pagamento </t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link className="sidebarlist"  to= {`/add-lingue/${userId}`}>
        <i class="fas fa-language"/> <t className="hide-sm">Lingue </t>
        </Link>
        </li>
        </div>
         <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/curriculum/${userId}`}>
        <i class="far fa-file"/>  <t className="hide-sm">Curriculum </t>
        </Link>
        </li>
        </div> 
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-pagamenti/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Estract Pay</t>
        </Link>
        </li>
        </div>
        <div className="divsid" >
        <li>
        <Link  className="sidebarlist"  to= {`/list-event/${userId}`}>
        <i class="fas fa-file-csv"/>  <t className="hide-sm">Estract Eve </t>
        </Link>
        </li>
        </div>
        <div className="divsid">
          <div className="Bottoneeliminauser">
            <button className="btn btn-danger" onClick={() => deleteHandler(userId)}>
              <i className="fas fa-user-minus" /> <t className="hide-sm">Delete Account</t>
            </button>
            </div>
          </div>
       
       
        </ul>
        </div>
        </div>
        <div className="contdashPrint">
          <ComponentToPrint test={userId} ref={componentRef} />
          <div>
        <button className="btnEstrai" onClick={handlePrint}>Salva CV {' '}<i class="fas fa-file-pdf"></i></button>
        </div>
        </div>
        
        <div>
          
        </div>
      </div>
    );
  };
  export default Print;