import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import ProfileExperience from './ProfileExperience';
import ProfileEducation from './ProfileEducation';
import ProfileProject from './ProfileProject';
import ProfilePayment from './ProfilePayment';
import {getUserDetails,deleteUser, getUserByid ,deleteUserExperienceAdmin, deleteUserEducationAdmin, deleteUserProjectAdmin, deleteUserPaymentAdmin} from '../../actions/userActions'
import formatDate from '../../utils/formatDate';
import Evento from '../dashboard/Evento';
import Sidebar from '../dashboard/Sidebar';
import SidebarAdmin from '../Admin/SidebarAdmin';
import Header from '../layout/Header';
import EventoAdmin from '../Admin/sidebarAdmin/EventoAdmin';
import TabEvent from '../Admin/sidebarAdmin/TabEvent'
import { USER_BYID_RESET, USER_DETAILS_RESET } from '../../constants/userConstants';
import TabEventUser from '../dashboard/TabEventUser';



const ListEventiUser = ({match,  history, location }) => {
  
  
  const dispatch = useDispatch()
  const userId = match.params.id
  //const userLogin = useSelector((state) => state.userLogin)
  //const { userInfo } = userLogin

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails
  console.log(userInfo)
  
  // const deleteHandler = (_id) => {
  
  //   if (window.confirm('Sei sicuro?')) {
  //     dispatch(deleteUser(_id))
      
  //     history.push('/profiles')
  //   }
  // }
  

  // const pippo = () => {
    
  //   window.location=('/profiles')
  //   dispatch({
  //     type: USER_DETAILS_RESET,
  //   })
  // }

  useEffect(() => {
    if (!userInfo ) {
      history.push('/login')
    } 
    else {
      
      if (!user || !user.name ) {
        
        dispatch(getUserDetails(userInfo._id))
        //history.push(`/profile/${userId}`)
       
     

        dispatch(getUserDetails(userInfo._id))


        
       
  
      } 
    }
  }, [dispatch,userInfo, history,  /*userInfo*/ ])
  
  



  return (
    <Fragment>
    <Header />
   <Sidebar/>
        <div className='contdash'>
    {userInfo.name !== undefined ? (
      <Fragment>
    
<TabEventUser dipendente={userInfo._id}/>


            
     
      </Fragment>
    
    ) : (
    <Fragment>
      <Spinner />
      
    </Fragment>
    )}</div>
     </Fragment>
     
    );
  };




  

export default ListEventiUser;
