import React, { Fragment, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET,USER_UPDATE_SUCCESS} from '../../../constants/userConstants'
import { getUserByid, addUserEducationAdmin } from '../../../actions/userActions';
import Header from '../../layout/Header';

const AddEducationAdmin = ({ location,match, history }) => {
  const userId = match.params.id
  const [school, setSchool] = useState('')
  const [degree, setDegree] = useState('')
  const [fieldofstudy, setFieldofstudy] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [current, setCurrent] = useState(false)
  const [description, setDescription] = useState('')
  
  const dispatch = useDispatch()
  
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  
  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid

  const userAddEducation = useSelector((state) => state.userAddEducation)
  const { success } = userAddEducation

  const redirect = location.search ? location.search.split('=')[1] : `/profile/${userId}`


 
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user || !user.username || success) {
        
        
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserByid(userId))
        
        
   
      } 
    }
  }, [dispatch, history, userInfo, user, success])


const submitHandler = (e) => {
  e.preventDefault()
  dispatch(getUserByid(userId))
    dispatch(addUserEducationAdmin(userId, { school, degree, fieldofstudy, from, to, current, description}))
    dispatch(getUserByid(userId))
    //dispatch({ type: USER_UPDATE_SUCCESS})
      history.push(redirect)
    
}

  
  return (
    <Fragment>
      <Header />
      <h1 className="large text-primary">Aggiungi studi a {user.name} {user.surname}</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Aggiungi scuole effettuate o corsi eseguiti
       
      </p>
      <small>* = campo richiesto</small>
      <form
        className="form"
        onSubmit={submitHandler}
      >
        <div className="form-group">
          <input
            type="text"
            placeholder="* Scuola o Corso"
            name="school"
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="*Grado o Certificazione"
            name="degree"
            value={degree}
            onChange={(e) => setDegree(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Corso di studi"
            name="fieldofstudy"
            value={fieldofstudy}
            onChange={(e) => setFieldofstudy(e.target.value)}
          />
        </div>
        <div className="form-group">
          <h4>Data inizio</h4>
          <input type="date" name="from" value={from} onChange={(e) => setFrom(e.target.value)} />
        </div>
        <div className="form-group">
          <p>
            <input
              type="checkbox"
              name="current"
              checked={current}
              value={current}
              onChange={(e) => setCurrent(!current)}
            />{' '}
            Scuola Attuale
          </p>
        </div>
        <div className="form-group">
          <h4>Data fine</h4>
          <input
          placeholder=""
            type="date"
            name="to"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            disabled={current}
          />
        </div>
        <div className="form-group">
          <textarea
            name="description"
            cols="30"
            rows="5"
            placeholder="Descrizione Programma"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to={`/profile/${userId}`}>
          Indietro
        </Link>
      </form>
    </Fragment>
  );
};


export default AddEducationAdmin;
