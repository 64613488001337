import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {  deleteUserPayment  } from '../../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants'
import formatDate from '../../utils/formatDate';
import { getUserDetails,deleteUser } from '../../actions/userActions'


const Payment = ({  history  }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails



  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!userInfo.token || !userInfo.username ) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails(userInfo._id))

        
      } 
          }
  }, [dispatch, history, userInfo])
  var payments=[];
  if(userInfo.payment===null){
    
  }else{
  payments = userInfo.payment.map((pay) => (
    <tr key={pay._id}>
      <td>{pay.title}</td>
      
      <td>{pay.amount}€</td>
      <td>
        {formatDate(pay.from)} a {formatDate(pay.to)}
      </td>
      <td></td>
      <td>
        <button
          onClick={() => deleteUserPayment(pay._id)}
          className="btn btn-danger"
        >
        <i class="fas fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  ));
  }
  return (
    <Fragment >
    <div className="contdash">
      <h2 className="my-2">
      Pagamenti</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Mese</th>
            <th >Saldo</th>
            <th >Data</th>
            <th>File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{payments}</tbody>
      </table>
      </div>
    </Fragment>
  );
};



export default Payment;
