import React, { Fragment, useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'
import { getUserByid , addUserPaymentAdmin } from '../../../actions/userActions';
import Header from '../../layout/Header';
import FileUpload from './FileUpload';
import axios from'axios';
const AddPaymentAdmin = ({match, location, history}) => {
  const userId = match.params.id
  const [title, setTitle] = useState('')
  const [saldo, setSaldo] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [file, setFile] = useState(new File([" "], " "))
  const [filename, setFilename] = useState('Choose File');
  const [description, setDescription] = useState('')

  const dispatch = useDispatch()

 
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userByid = useSelector((state) => state.userByid)
  const { loading, error, user } = userByid

  const userAddPayment = useSelector((state) => state.userAddPayment)
  const { success } = userAddPayment

  const redirect = location.search ? location.search.split('=')[1] : `/profile/${userId}`


 
  useEffect(() => {
    if (!user) {
      history.push('/login')
    } else {
      if (!user || !user.username || success) {
       
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserByid(userId))
        
        
   
      } 
    }
  }, [dispatch, history, userInfo, user, success])


const submitnew = async e => {
 
 const pippo = document.getElementById('customFile').value

 if (pippo=== " "){
   return
 }
 if (pippo=== " "){
  return
}
 const formData = new FormData();
 formData.append('name',"pagamenti/"+file.name);
 formData.append('mimetype',file.type);
 formData.append('file', file);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    }
    
    // await axios.post(`https://api.gestionale.ipsedocet.com/api/users/${userId}/upload/payment`, formData, config, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }})
     console.log(file)
     console.log(formData)
     

     await axios.post(`https://api.gestionale.ipsedocet.com/bucket`, formData,config, {
  
    })

    // const { fileName, filePath } = res.data;

    // setUploadedFile({ fileName, filePath });

   alert('File Uploaded');
  } catch (err) {
    if (err.response.status === 500) {
      alert('There was a problem with the server');
    } else {
      console.log(file)
     
      alert("Qualcosa è andato storto")
     
      //alert(err.response.data.msg);
    }
  }
};



const onChange = e => {
  
  
  setFile(e);
  setFilename(e.name);
};
const submitHandler = (e) => {
  e.preventDefault()
  dispatch(getUserByid(userId))
 
  submitnew(file)

  const filecaricato = file.name;
  
    dispatch(addUserPaymentAdmin(userId, {  title, saldo, from, to, filecaricato:filecaricato}))
    dispatch(getUserByid(userId))
       history.push(redirect)
    
}
  return (
    <Fragment>
      <Header />
      <h1 className="large text-primary">Agg. Pagamento a {user.name} {user.surname}</h1>
      <p className="lead">
        <i className="fas fa-code-branch" /> Aggiungi pagamento
        
      </p>
      <small>* = campo richiesto</small>
      <form
        className="form"
        onSubmit={submitHandler}
      >
        <div className="form-group">
          <input
            type="text"
            placeholder="*Mese Pagamento"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
        <input
          type="text"
          placeholder="*Saldo"
          name="saldo"
          value={saldo}
          onChange={(e) => setSaldo(e.target.value)}
          required
        />
      </div>
       
        
        <div className="form-group">
          <h4>Data Inizio</h4>
          <input placeholder="" type="date" name="from" value={from} onChange={(e) => setFrom(e.target.value)}/>
        </div>
       
        <div className="form-group">
          <h4>Data Fine</h4>
          <input
          placeholder=""
            type="date"
            name="to"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            
          />
        </div>
       
       <div className="form-group">
          <input
          required
            name="file"
            type="file"
            id='customFile'
            enctype='multipart/form-data'
           // value={file}
            onChange={(e) =>{onChange(e.target.files[0])}}
          />
  </div>
        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to={`/profile/${userId}`}>
          Indietro
        </Link>
      </form>
    </Fragment>
  );
};



export default AddPaymentAdmin;
