import { config } from '@fullcalendar/react';
import axios from 'axios'
// import { Redirect } from 'react-router'
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,


  USER_BYID_REQUEST,
  USER_BYID_SUCCESS,
  USER_BYID_FAIL,
  USER_BYID_RESET,

  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,


  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,


  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,


  USER_DETAILS_RESET,


  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,


  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,

  
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,

  LINGUE_DELETE_REQUEST,
  LINGUE_DELETE_SUCCESS,
  LINGUE_DELETE_FAIL,


  EXPERIENCE_DELETE_REQUEST,
  EXPERIENCE_DELETE_SUCCESS,
  EXPERIENCE_DELETE_FAIL,

          EXPERIENCE_DELETE_ADMIN_REQUEST,
          EXPERIENCE_DELETE_ADMIN_SUCCESS,
          EXPERIENCE_DELETE_ADMIN_FAIL,

  EDUCATION_DELETE_REQUEST,
  EDUCATION_DELETE_SUCCESS,
  EDUCATION_DELETE_FAIL,

  EVENTO_DELETE_REQUEST,
  EVENTO_DELETE_SUCCESS,
  EVENTO_DELETE_FAIL,

  EVENTO_DETAILS_REQUEST,
  EVENTO_DETAILS_SUCCESS,
  EVENTO_DETAILS_FAIL,

  EVENTO_DETAILS_USER_REQUEST,
  EVENTO_DETAILS_USER_SUCCESS,
  EVENTO_DETAILS_USER_FAIL,

  PROJECT_DELETE_REQUEST,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_FAIL,

  PAYMENT_DELETE_REQUEST,
  PAYMENT_DELETE_SUCCESS,
  PAYMENT_DELETE_FAIL,

  DOCUMENTI_DELETE_REQUEST,
  DOCUMENTI_DELETE_SUCCESS,
  DOCUMENTI_DELETE_FAIL,
  USER_UPDATE_PROFILE_RESET,
} from '../constants/userConstants'

var noError=false;

export const login = (email, password, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      'https://api.gestionale.ipsedocet.com/api/users/login',
      { email, password },
      config
    )
   noError=true;
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message
      if(!noError){
        alert(message )
        // history.push('/login')
        // dispatch({type:USER_LOGOUT} )
        // localStorage.removeItem('userInfo')
      }
   
   
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
    dispatch({type:USER_LOGOUT} )
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')

  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_DETAILS_RESET })
  dispatch({ type: USER_LIST_RESET })
  document.location.href = '/login'
}

export const register = (username, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',

      },
    }

    const { data } = await axios.post(
      'https://api.gestionale.ipsedocet.com/api/users',
      { username, email, password },
      config
    )

    noError=true;
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message
      if(!noError){
        alert(message )
      }
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserDetails = (id, history) => async (dispatch, getState) => {
  try {
   

    
    dispatch({
      type: USER_DETAILS_REQUEST,
    })
   
    

    const {
      userLogin: { userInfo },
    } = getState()
    
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
    
    const { data } = await axios.get(`https://api.gestionale.ipsedocet.com/api/users/${id}/`, config)
    
   

    //   dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // })
     dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    noError=true;
  } catch (error) {
   
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

        if(!noError){
          alert(message )
        }

    if (message === 'Request failed with status code 401') {
       dispatch(logout())
      
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const getUserByid = (id) => async (dispatch, getState) => {
  try {
    

    const {
      userLogin: { userInfo },
    } = getState()

   
    dispatch({
      type: USER_BYID_REQUEST,
    })
    
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
    
    const { data } = await axios.get(`https://api.gestionale.ipsedocet.com/api/users/anagrafica/${id}`, config)
    //const userId = data._id
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
   {/* alert('###')
    Redirect(`/profile/${userId}`)
  alert('111')*/}

  noError=true;
    
  } catch (error) {
   
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_BYID_FAIL,
      payload: message,
    })
   // history.push(`/profile/${user._id}`)
  }
}
export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
   
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/anagrafica/${userInfo._id}`, user, config)

    noError=true;
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_DETAILS_RESET,
      payload: data,
    })
    
    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // })
    
    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    console.log('errore');
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}


export const addUserLingue= (user, history) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
 
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/lingue/${userInfo._id}`,user, config)

    noError=true;
    
  
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })

    window.location.reload=('/dashboard')
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const addUserLingueAdmin= (user, userId) => async (dispatch, getState) => {
  try {
    
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }


    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/lingue/admin/${user}`,userId, config)

    
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
  
    noError=true;
    //window.location('/dashboard')
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        alert(message)
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserLingue= (id) => async (dispatch, getState, history) => {
  try {
    dispatch({
      type: LINGUE_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  
    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/lingue/${id}`, config)
    noError=true;
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // })
    dispatch({
      type: LINGUE_DELETE_SUCCESS,
      payload: data,
    })
   

    dispatch({
      type: USER_UPDATE_SUCCESS,
      
    })
    // alert("rfeset")
    // dispatch({
    //   type: USER_DETAILS_RESET,
      
    // })
    // alert("ciao")

    
    //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: LINGUE_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserLingueAdmin= (userId, id) => async (dispatch, getState, history) => {
  try {
    dispatch({
      type: LINGUE_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  
    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/lingue/admin/${userId}/${id}`, config)
    /*dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })*/
    noError=true;
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })

    dispatch({
      type: LINGUE_DELETE_SUCCESS,
      payload: data,
    })

    
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      
    })
    //history.push('dashboard')

    
    //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: LINGUE_DELETE_FAIL,
      payload: message,
    })
  }
}



export const addUserExperience= (user, history) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
 
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/experience/${userInfo._id}`,user, config)
    noError=true;
    
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    
    //window.location('/dashboard')
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const addUserExperienceAdmin= (user, userId) => async (dispatch, getState) => {
  try {
    
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }


    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/experience/admin/${user}`,userId, config)
    noError=true;

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
  

    //window.location('/dashboard')
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserExperience= (id) => async (dispatch, getState, history) => {
  try {
    dispatch({
      type: EXPERIENCE_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  
    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/experience/${id}`, config)
    noError=true;
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    dispatch({
      type: EXPERIENCE_DELETE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_UPDATE_SUCCESS,
      
    })
    

    
    //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EXPERIENCE_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserExperienceAdmin= (userId, id) => async (dispatch, getState, history) => {
  try {
    dispatch({
      type: EXPERIENCE_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  
    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/experience/admin/${userId}/${id}`, config)
    /*dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })*/
    noError=true;
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })

    dispatch({
      type: EXPERIENCE_DELETE_SUCCESS,
      payload: data,
    })

    
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      
    })
    //history.push('dashboard')

    
    //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EXPERIENCE_DELETE_FAIL,
      payload: message,
    })
  }
}
export const addUserEducation= (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
 
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/education/${userInfo._id}`,user, config)
    noError=true;
    
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}


export const addUserEducationAdmin= (user, userId) => async (dispatch, getState) => {
  try {
    
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }


    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/education/admin/${user}`,userId, config)
    noError=true;

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
  

    //window.location('/dashboard')
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserEducation= (id) => async (dispatch, getState, history) => {
  try {
    dispatch({
      type: EDUCATION_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
   
    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/education/${id}`, config)
    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    dispatch({
      type: EDUCATION_DELETE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_UPDATE_SUCCESS,
      
    })
    noError=true;
 // dispatch({
  //    type: USER_UPDATE_PROFILE_SUCCESS,
   //   payload: data,
   //})

    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EDUCATION_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserEducationAdmin= (userId, id) => async (dispatch, getState, history) => {
  try {
    dispatch({
      type: EDUCATION_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/education/admin/${userId}/${id}`, config)
    /*dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })*/
    noError=true;
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    dispatch({
      type: EDUCATION_DELETE_SUCCESS,
      payload: data,
    })
  dispatch({
     type: USER_UPDATE_PROFILE_SUCCESS,
      
   })

    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EDUCATION_DELETE_FAIL,
      payload: message,
    })
  }
}


export const addUserEvento= (user) => async (dispatch, getState) => {
  
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/${userInfo._id}/`,user, config)
    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // })
    noError=true;
    
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
   
  //  alert("Evento aggiunto con successo")
   window.location.reload( `/dashboard`);

   noError=true;
     //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      
        if(!noError){
          alert(message )
        }
       
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const addUserEventoAdmin= (user, userId) => async (dispatch, getState, location) => {
  try {
    
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  

    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/admin/${user}`,userId, config)


    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    // alert("Evento aggiunto con successo")
    window.location.reload( `/`);
    noError=true;
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}


export const getUserEvento= (user, eventoId) => async (dispatch, getState, location) => {
  try {
    dispatch({
      type: EVENTO_DETAILS_USER_REQUEST,
    })
   

    // const {
    //   userLogin: { userInfo },
    // } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  

    const { data } = await axios.get(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/${user}`, config)


   
    dispatch({
      type: EVENTO_DETAILS_USER_SUCCESS,
      payload: data,
    })
    
   
    noError=true;
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EVENTO_DETAILS_USER_FAIL,
      payload: message,
    })
  }
}



export const getUserEventoAdmin= (user, eventoId) => async (dispatch, getState, location) => {
  try {
    dispatch({
      type: EVENTO_DETAILS_REQUEST,
    })
   

    // const {
    //   userLogin: { userInfo },
    // } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  

    const { data } = await axios.get(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/admin/${user}/${eventoId}`, config)

console.log(eventoId)
   
    dispatch({
      type: EVENTO_DETAILS_SUCCESS,
      payload: data,
    })
    
   
    noError=true;
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EVENTO_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const editUserEvento = ( eventId, body) => async (dispatch, getState, location) => {
  try {
    
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  

    const { data } = await axios.post(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/${eventId}`,body, config)


    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  
    window.location.reload( `/`);
    noError=true;
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    })
  }
}


export const editUserEventoAdmin= (user, userId, evento) => async (dispatch, getState, location) => {
  try {
    
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  

    const { data } = await axios.post(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/admin/`+ user + "/"+ userId,evento, config)


    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    
    window.location.reload( `/`);
    noError=true;
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserEventoAdmin= (userId, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENTO_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
  
    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/admin/${userId}/${id}`, config)

    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    dispatch({
      type: EVENTO_DELETE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      
    })
    noError=true;
  //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EVENTO_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserEvento= (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENTO_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
   
    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/evento/${id}`, config)
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    dispatch({
      type: EVENTO_DELETE_SUCCESS,
      payload: data,
    })

   
    dispatch({
      type: USER_UPDATE_SUCCESS,
      
    })
   
    noError=true;
  //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: EVENTO_DELETE_FAIL,
      payload: message,
    })
  }finally{
    
  }
}


export const addUserPayment= (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
 
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/payment/admin/${userInfo._id}`,user, config)


    noError=true;
    
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}

export const addUserPaymentAdmin= (user, userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/payment/admin/${user}`,userId, config)

    noError=true;
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}
export const deleteUserPayment= (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PAYMENT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/payment/${id}`, config)
   
   
    noError=true;
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    
    dispatch({
      type: PAYMENT_DELETE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_UPDATE_SUCCESS,
      
    })
    //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: PAYMENT_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserPaymentAdmin= (userId, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PAYMENT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/payment/admin/${userId}/${id}`, config)
   
   
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })

    dispatch({
      type: PAYMENT_DELETE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      
    })
    noError=true;
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: PAYMENT_DELETE_FAIL,
      payload: message,
    })
  }
}


export const addUserDocumentiAdmin= (user, userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }
    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/documenti/admin/${user}`,userId, config)

    noError=true;
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}



export const deleteUserDocumentiAdmin= (userId, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOCUMENTI_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/documenti/admin/${userId}/${id}`, config)
   
   
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })

    dispatch({
      type: DOCUMENTI_DELETE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      
    })
    noError=true;
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: DOCUMENTI_DELETE_FAIL,
      payload: message,
    })
  }
}




export const addUserProject= (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/project/${userInfo._id}`,user, config)
    noError=true;
    
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
   //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}


export const addUserProjectAdmin= (user, userId) => async (dispatch, getState) => {
  try {
    
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

   

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }


    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/profile/project/admin/${user}`,userId, config)


    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    noError=true;

    //window.location('/dashboard')
    
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    })
  }
}
export const deleteUserProject= ( id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROJECT_DELETE_REQUEST,
      
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/project/${id}`, config)
    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // })
    noError=true;
    
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
    
   //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: PROJECT_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteUserProjectAdmin= (userId, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROJECT_DELETE_REQUEST,
      
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/profile/project/admin/${userId}/${id}`, config)
    dispatch({
      type: USER_BYID_SUCCESS,
      payload: data,
    })
    dispatch({
      type: PROJECT_DELETE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      
    })
    noError=true;
   //dispatch({
      //type: USER_LOGIN_SUCCESS,
      //payload: data,
   // })
    //localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: PROJECT_DELETE_FAIL,
      payload: message,
    })
  }
}

export const listUsers = () => async (dispatch, getState) => {
  try {
  
   
    dispatch({
      type: USER_LIST_REQUEST,
    })
   
    
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.get(`https://api.gestionale.ipsedocet.com/api/users`, config)
 
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    })
    
    noError=true;
  
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        alert(message)
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    })
  }
}

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`https://api.gestionale.ipsedocet.com/api/users/${id}`, config)

    dispatch({ type: USER_DELETE_SUCCESS })
    noError=true;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    })
  }
}

export const updateUser = (user, userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/modifica/admin/${user}`,  userId,config, )

    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS })

    dispatch({ type: USER_BYID_SUCCESS, payload: data })
    noError=true;

    //dispatch({ type: USER_BYID_RESET })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        alert(message)
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    })
  }
}


export const updateBlocco = (user,userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
      },
    }

    const { data } = await axios.put(`https://api.gestionale.ipsedocet.com/api/users/admin/${user}`,  userId,config, )

    dispatch({ type: USER_UPDATE_SUCCESS })

    dispatch({ type: USER_BYID_SUCCESS, payload: data })

    dispatch({ type: USER_BYID_RESET })
    noError=true;
    
  } catch (error) {
    
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        if(!noError){
          alert(message )
        }
    if (message === 'Request failed with status code 401') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    })
  }
}





// const uploadPayment =(req, res) => {
//   upload(req, res, (err) => {
//    if(err) {
//      res.status(400).send("Something went wrong!");
//    }
//    res.send(req.file);
//  });
// };