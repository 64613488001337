import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import formatDate from '../../utils/formatDate';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import Modal from 'react-modal';

import AddEvento from '../profile-forms/AddEvento';
import {deleteUserEvento, getUserDetails, getUserEvento } from '../../actions/userActions';
import EditEvento from '../Admin/sidebarAdmin/EditEvento';
import { EVENTO_DETAILS_RESET, EVENTO_DETAILS_SUCCESS, EVENTO_DETAILS_USER_FAIL, EVENTO_DETAILS_USER_RESET, EVENTO_DETAILS_USER_SUCCESS, USER_DETAILS_RESET, USER_UPDATE_REQUEST } from '../../constants/userConstants';
import { Redirect } from 'react-router';




const Evento = ({ evento, location }) => {
  var elimina=false;
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userDeleteEvento = useSelector((state) => state.userDeleteEvento)
  const { successDeleteEvento } = userDeleteEvento

  // const userGetEvento = useSelector((state)=> state.userGetEvento)
  // const {eventodata}= userGetEvento

  const [blocco, setBlocco]= useState("")
  //const porcodio = eventInfo.event.extendedProps._id
  const deleteEvento= (_id, e) => {
    elimina=true;
    console.log(elimina)
    console.log(e)
    // setModalIsOpenEdit(false)
   
     if (window.confirm('Sei Sicuro?')) {
    
      // dispatch(getUserDetails(userInfo._id))
      
        dispatch(deleteUserEvento(_id))
       

        dispatch(getUserDetails(userInfo._id))
        //window.location.reload( `/profile/`);
        // e.preventDefault()
        // e.stopPropagation()

      
      
     }
     dispatch(getUserDetails(userInfo._id))
    }

    function EditEVENTO(info, data) {
      // dispatch(getUserEvento(info.event.extendedProps._id))
      // dispatch({type:EVENTO_DETAILS_SUCCESS,
      //   payload:eventodata})
      setTimeout(() => {
        if(elimina){
          return
        }
  // alert("ciaso")
  // dispatch(getUserDetails(userInfo._id))
        const a =info.event._instance.range.start.toISOString()
        
        const giornoEvento = a.slice(0,10) 
        
        // dispatch(getUserEvento(info.event.extendedProps._id))
      
        // dispatch({type:EVENTO_DETAILS_SUCCESS,
        //   payload:eventodata})
        //    console.log(eventodata)
        //   console.log({payload:eventodata})
        setIdEvento(info.event.extendedProps._id) 
        //  setTitolo(eventodata.title)
        //  setNore(eventodata.ore) 
        //  setGdate(eventodata.data)
        
        //  setStarteve(eventodata.start) 
        //  setEndeve(eventodata.end)
        //  setNorepermessi(eventodata.orepermessi)
        //  setNoreferie(eventodata.oreferie)
        //  setNoremutua(eventodata.oremutua)
        //  setNorestraordinario(eventodata.orestraordinario)
        //  setTipoevento(eventodata.tipoevento)
        //  setGnote(eventodata.note)
              // alert("AIUTO")
              // alert("AIUTO" + provaccia)
              //       setProvaccia((info.event.extendedProps._id))
                    
                  
              // dispatch(USER_UPDATE_REQUEST)  
         setModalIsOpenEdit(true)
      }, 10);
      // console.log(info.jsEvent)
      
      // info.jsEvent.stopPropagation()

  
      }  


  function renderEventContent(eventInfo) {
    // console.log(eventInfo.event._def.extendedProps)
    // console.log(eventInfo)
    // console.log(eventInfo.event._instance.range.data)
     
    return (
   
      <div
      className="contCal"
      style={ 
        {
          
          background:' #0077ff',         
          paddingTop:'1px',
          paddingBottom:'10px',
          paddingLeft:'1%',
          height:'130px',
          marginBottom:'1px',
          width:'100%',
          zIndex:'100',
          marginTop:'10%'
          
        }
      }
      >
        <div >
        <div>
         
        <p className="deleteEvento" onClick={(e)=>deleteEvento(eventInfo.event.extendedProps._id, e)} >X</p>
        <h3 style={
          {
            color: 'black',
            paddingLeft:"4%"
          }
        }>{eventInfo.event.title.substring(0,10)}</h3>
        </div>
        <div>
        <h5
        style={
          {
            fontSize:'0.8rem',
            color: 'white ',
            paddingLeft:"4%"
          }
        }
        >Lavorate: {eventInfo.event.extendedProps.ore}</h5>
        <h6 style={
          {
            fontSize:'0.8rem',
            color: 'white ',
            paddingLeft:"4%"
          } }> Straordinario: {eventInfo.event.extendedProps.orestraordinario}</h6> 
          <h6 style={
            {
              fontSize:'0.8rem',
              color: 'white ',
              paddingLeft:"4%"
            } }>Ferie: {eventInfo.event.extendedProps.oreferie}</h6> <h6 style={
              {
                fontSize:'0.8rem',
                color: 'white ',
                paddingLeft:"4%"
              } }> Mutua: {eventInfo.event.extendedProps.oremutua}</h6><h6 style={
                {
                  fontSize:'0.8rem',
                  color: 'white ',
                  paddingLeft:"4%"
                } }> Permessi: {eventInfo.event.extendedProps.orepermessi}</h6>

        </div>
        
        <p>  {eventInfo.event.extendedProps.start}</p>
        <p>  {eventInfo.event.extendedProps.end}</p>
        

        
        
        
        
        
        </div>
       
      </div>
    )

    }
   const apritiSesamo = (e)=>{
      // console.log( e.date.toISOString());
     console.log( e.date.getDay());
     console.log( e.date.getDate())
     console.log( e.date.getMonth())
     console.log( e.date.getYear())
     console.log( e.date.getTime())
     console.log( e.date.getDate())
      
      setGiornoData((e.dateStr))

      setmodalIsOpen1(true)
    }
 
    
    const [idUser, setIdUser]=useState('');
    const [idEvento, setIdEvento ]=useState('');
    
    const [titolo, setTitolo ]=useState('');
    const [nore, setNore]=useState('');
    const [gdate, setGdate]=useState('');
    const [starteve,setStarteve] =useState('');
    const [endeve, setEndeve]=useState(''); 
    const [norepermessi, setNorepermessi] =useState('');
    const [noreferie, setNoreferie]=useState(''); 
    const [noremutua, setNoremutua] =useState('');
    const [norestraordinario, setNorestraordinario] =useState('');
    const [gtipoevento, setTipoevento] =useState('');
    const [gnote, setGnote] =useState('');

const [giornoData,setGiornoData]=useState('prova');
const [modalIsOpen1, setmodalIsOpen1] = useState(false) 
const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false)
const [modalIsOpenEstrai, setmodalIsOpenEstrai] = useState(false)
const [modalIsOpenBlocca, setmodalIsOpenBlocca] = useState(false)  

const a = 1
const b = 25
{console.log("onIinit")}
const pippo = a + b

function RESET(){
  

setModalIsOpenEdit(false) 
// dispatch(EVENTO_DETAILS_USER_RESET)
}



  return (
   
    <div className="contCalendar">
    
    
    
    
    <Modal
    isOpen={modalIsOpenBlocca} 
    onRequestClose={()=> setmodalIsOpenBlocca(false)}
    >
      
    <p onClick={()=>setmodalIsOpenBlocca(false)} className='chiusuraModal'>X</p>
    </Modal>
    
    <Modal
    isOpen={modalIsOpenEstrai} 
    onRequestClose={()=> setmodalIsOpenEstrai(false)}
    
    >
    <p onClick={()=>setmodalIsOpenEstrai(false)} className='chiusuraModal'>X</p>
    </Modal>
    <Modal 
    className='ModalEvent'
    isOpen={modalIsOpen1} 
    onRequestClose={()=> setmodalIsOpen1(false)}
    style={
     {
       
      

      overlay:{
        zIndex:'100 ',
       opacity:'100%'
     },
      content:{
        color: '#fff',
        backgroundColor:' #0077ff',
        width:'400px',
        height:'610px',
        marginLeft: '35%',
        marginTop:'2%',
        marginBottom:'50px',
        position:'inherit',
        opacity:'100%',
        borderRadius:'30px',
        zIndex:'100 ',
        
      }
    }
    }
    >
<p onClick={()=>setmodalIsOpen1(false)} className='chiusuraModal'>X</p>
    <AddEvento className="modalCont"  giorno={giornoData}/>
    
    </Modal>
    <Modal 
    className='ModalEvent'
    isOpen={modalIsOpenEdit} 
    onRequestClose={()=> setModalIsOpenEdit(false)}
    style={
     {
       
      

      overlay:{
        zIndex:'100 ',
       opacity:'100%'
     },
      content:{
        color: '#fff',
        backgroundColor:' #0077ff',
        width:'400px',
        height:'610px',
        marginLeft: '35%',
        marginTop:'2%',
        marginBottom:'50px',
        position:'inherit',
        opacity:'100%',
        borderRadius:'30px',
        zIndex:'100 ',
        
      }
    }
    }
    >
<p onClick={()=>RESET()} className='chiusuraModal'>X</p>
    <EditEvento id="pippo" className="modalCont" idEvento={idEvento} titolo={titolo} nore={nore} starteve={starteve} endeve={endeve} norepermessi={norepermessi} noreferie={noreferie} noremutua={noremutua} norestraordinario={norestraordinario} gtipoevento={gtipoevento} gnote={gnote}/*idEvento={eventInfo.event.extendedProps._id}*/ idUser={user._id}  giorno={gdate}/>
    
    </Modal>
    
    
    <div className="contenitoreCal">
    {console.log("onIinit")}
    <FullCalendar
    style={
      {
        width:'400px',
        zIndex:'1'
    }}
    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
    initialView="dayGridMonth"
    
    headerToolbar={{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }}
    firstDay={1}
    locale='it'
    selectable={true}
    validRange= {{
      start: userInfo.blocco
   }}
    events={evento}
    // events={[
    //   { title: 'event 1',  start:'2021-01-21', ore:'8',  end:'2021-01-21' },
    //   { title: 'LAVORATO', start: '2021-01-05T15:00:00', end: '2021-01-05T18:00:00'  }
    // ]}
    eventContent={renderEventContent}
    editable={false}
    // dateClick={()=>setmodalIsOpen(true)}
    dateClick={(e)=>apritiSesamo(e)}
    eventClick={(info)=>{
      // cambioID(info) 
      setModalIsOpenEdit(false)
      EditEVENTO(info);
      
      }}
    //all-day={false}
    />
    
    </div>
    
   </div>
  );
  
};






export default Evento;
